import BaseApi from '@/api/BaseApi';
import type { IArticleListV2, IArticleQueryParams } from '@/models/ArticlesModel';

export default class ArticleApi extends BaseApi {
	async articleList(params: IArticleQueryParams, isOfferFeedPage?: boolean) {
		let url = `/api/v2/articles/available?page=${params.page}&per_page=${params.perPage}`;

		if (params.title && params.direction) {
			url += `&order=${params.title}&direction=${params.direction}`;
		} else {
			url += '&order=updated&direction=desc';
		}

		if (params.search) {
			url += `&search=${params.search}`;
		}

		if (params.languageId) {
			url += `&language_id=${params.languageId}`;
		}

		if (params.campaignId) {
			url += `&campaign_id=${params.campaignId != null && params.campaignId !== 'null' ? params.campaignId : 'default_campaign_uuid'}`;
		}

		if (params.userUuid) {
			url += `&user_uuid=${params.userUuid}`;
		}

		if (params.articleType) {
			url += `&article_type=${params.articleType}`;
		}

		if (params.isDashboardView) {
			url += '&preview=1';
		}

		return this.get<IArticleListV2>({
			url: url,
			config: {
				timeout: 60000,
			},
		});
	};
};
