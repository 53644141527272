import axios from '@/plugins/api-axios'
import { toast } from 'vue3-toastify';
import {
    bearerToken,
    getToastError,
    successResult,
    failureResult,
    getTimeZoneHeader,
    getLocaleHeader,
    responseHandler
} from './helpers'
import {
    type ApiConfiguration,
    type ApiFailureHandler,
    type ApiOptions,
    type ApiResponse,
    type ApiSuccessHandler,
    type DeleteProperties,
    type GetProperties,
    type MakeAjaxProperties,
    type PatchProperties,
    type PostProperties,
    type PutProperties
} from './types'

import { type AxiosError, type AxiosResponse } from 'axios'

const handleApiSuccess: ApiSuccessHandler = () => (response) => {
    const { data, status, headers } = response;

    return {
        isSuccess: true,
        payload: data,
        status,
	    headers
    };
};

const handleApiFailure: ApiFailureHandler = config => (content) => {
    const status = content?.response?.status || 200;

    if (config.suppressError) {
        toast.error(getToastError(content));
    }

    const payload = content?.response?.data || content || null;

    return {
        isSuccess: false,
        payload,
        status,
    } as any;
};

/**
 * Base API class.
 */
export default class BaseApi {
    defaultContentType: string;
    successResult: <T>(content: AxiosResponse<T>) => ApiResponse;
    failureResult: <T>(message: AxiosError<T>, options?: ApiOptions) => ApiResponse;
    bearerToken: () => string | null;

    constructor () {
        this.defaultContentType = 'application/json'
        this.successResult = successResult
        this.failureResult = failureResult
        this.bearerToken = bearerToken
    }

    /**
     * Retrieve the content type header, or default to this.defaultContentType.
     */
    getContentTypeHeader (contentType?: string) {
        return {
            'Content-Type': contentType || this.defaultContentType
        }
    }

    resolveBearerToken () {
        const token = this.bearerToken()
        return {
            Authorization: token ? `Bearer ${token}` : null
        }
    }
	resolveGoogleToken () {
		const googleToken = localStorage.getItem('googleToken');
		if (null != googleToken) {
			return {
				'google-token': googleToken || null
			};
		} else {
			return null;
		}
	};

    resolveHeaders (configuration: ApiConfiguration) {
        return {
            ...(configuration.useBearerToken ? this.resolveBearerToken() : null),
            ...this.getContentTypeHeader(configuration.contentType),
	        ...this.resolveGoogleToken(),
            ...(configuration.includeTimeZone ? getTimeZoneHeader() : null),
            ...(configuration.includeLocale ? getLocaleHeader() : null)
        }
    }

    resolveAxiosOptions (configuration: ApiConfiguration, params?: {}, timeout: number = 20000) {
        return {
            baseURL: configuration.useMonolithApi ? import.meta.env.VITE_MONOLITH_API_HOST : import.meta.env.VITE_API_HOST,
            headers: this.resolveHeaders(configuration),
            params,
            timeout: null != configuration.timeout ? configuration.timeout : timeout,
        }
    }

    async makeAjax<RequestContent, ResponseContent> (
        properties: MakeAjaxProperties<RequestContent>
    ) {
        const { url, method, params, config, data, timeout } = properties
        const useConfig = {
            suppressError: false,
            useBearerToken: true,
            includeTimeZone: false,
            includeLocale: false,
            contentType: 'application/json',
            useMonolithApi: false,
            ...config
        }

        const axiosOptions = this.resolveAxiosOptions(useConfig, params, timeout)

        const cleanUrl = '/' === url[0] ? url : `/${url}`
        const encodedUrl = encodeURI(`${cleanUrl}`)

        if ('get' === method || 'delete' === method) {
            return axios(useConfig.useMonolithApi)[method](encodedUrl, axiosOptions).then(
                handleApiSuccess<ResponseContent>(),
            ).catch(handleApiFailure(useConfig))
        }

        return axios(useConfig.useMonolithApi)[method](encodedUrl, data, axiosOptions).then(
            handleApiSuccess<ResponseContent>(),
        ).catch(handleApiFailure(useConfig))
    }

    async get<ResponseContent> (
        getProperties: GetProperties
    ) {
        const { url, params, config, timeout } = getProperties
        return this.makeAjax<void, ResponseContent>({
            method: 'get',
            url,
            params,
            config,
            timeout
        })
    }

    async post<RequestContent, ResponseContent> (
        postProperties: PostProperties<RequestContent>
    ) {
        const { url, data, params, config, timeout } = postProperties
        console.debug(url, data)
        return this.makeAjax<RequestContent, ResponseContent>({
            method: 'post',
            url,
            data,
            params,
            config,
            timeout
        })
    }

    async patch<RequestContent, ResponseContent> (
        patchProperties: PatchProperties<RequestContent>
    ) {
        const { url, data, params, config, timeout } = patchProperties
        return this.makeAjax<RequestContent, ResponseContent>({
            method: 'patch',
            url,
            data,
            params,
            config,
            timeout
        })
    }

    async put<RequestContent, ResponseContent> (
        putProperties: PutProperties<RequestContent>
    ) {
        const { url, data, params, config, timeout } = putProperties
        return this.makeAjax<RequestContent, ResponseContent>({
            method: 'put',
            url,
            data,
            params,
            config,
            timeout
        })
    }

    async delete<ResponseContent> (
        deleteProperties : DeleteProperties
    ) {
        const { url, params, config, timeout } = deleteProperties
        return this.makeAjax({
            method: 'delete',
            url,
            params,
            config,
            timeout
        })
    }
}
