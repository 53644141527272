<template>
	<div v-if="null != user" class="order-list-table" :class="{'order-list-table-dashboard transparent-scroll': isDashboardView}">
		<v-data-table-server
			:items="items"
			:headers="headers"
			:loading="loading"
			:loading-text="$t('components.loading')"
			:no-data-text="$t('components.noDataText')"
			:items-per-page-text="`${$t('components.rowsPerPage')}`"
			:page-text="`${currentPage} ${$t('components.of')} ${lastPage}`"
			:items-per-page-options="itemsPerPageOptions"
			v-model:page="options.page"
			:items-per-page="options.itemsPerPage"
			:sort-by="options.sortBy"
			:options="options"
			@update:options="optionsUpdate"
			:items-length="total"
			hover
		>
			<template v-slot:loading>
				<div class="order-list-table__loading">{{ $t('components.loading') }}</div>
			</template>
			
			<template #top>
				<OrderListSummary v-if="isAdvertiser(user) && !isDashboardView" @emitDates="emitedDates" />
				<div v-if="!isDashboardView" class="order-list-table__top-wrapper">
					<div class="order-list-table__search-wrapper">
						<MTextField
							id="order-list-search"
							v-model="search"
							name="search"
							:placeholder="$t('order.orderFlow.search')"
							clearable
						/>
					</div>
					<div v-if="user.is_agency" class="order-list-table__user-wrapper">
						<MAutocomplete
							id="order-dependent-account"
							v-model="dependentAccountUuid"
							:get-items="dependentAccountList"
							clearable
							variant="outlined"
							item-title="email"
							item-value="uuid"
							:placeholder="$t('order.orderFlow.selectAccount')"
						/>
					</div>
					<div v-if="isAdvertiser(user)" class="order-list-table__campaign-wrapper">
						<MAutocomplete
							id="order-campaign"
							v-model="campaign"
							:get-items="campaigns"
							clearable
							variant="outlined"
							item-title="name"
							item-value="uuid"
							:placeholder="$t('order.orderFlow.campaign')"
						/>
					</div>
					<div class="order-list-table__campaign-wrapper">
						<MAutocomplete
							id="order-languages"
							v-model="languageId"
							:get-items="languages"
							clearable
							variant="outlined"
							item-title="name"
							item-value="id"
							:placeholder="$t('order.orderFlow.languages')"
						/>
					</div>
					<div class="order-list-table__campaign-wrapper">
						<MAutocomplete
							id="order-status"
							v-model="status"
							:get-items="statusList"
							clearable
							variant="outlined"
							item-title="name"
							item-value="slug"
							:placeholder="$t('order.orderFlow.statusPlaceholder')"
						/>
					</div>
					
					<div class="order-list-table__table-settings-wrapper">
						<TableSettingsMenuWithModal :table-name="ORDER_GROUPS_LIST" :is-cleared-table-settings-action="isClearedTableSettingsAction" @headersMove="headersMoveAction" :on-clear-action="clearTableSettingsAction" />
					</div>
				</div>
			</template>
			
			<template #item.campaign="elem">
				<div v-if="null != elem.item.raw.order.campaign" class="order-list-table__bold m9-data-table-align-left order-list-table__dashboard-word-break" :class="isDashboardView ? ['order-list-table__dashboard-word-break order-list-table__dashboard', statusDashboardColor(elem.item.raw.status)] : ''">{{ elem.item.raw.order.campaign.name }}</div>
				<div v-if="null != elem.item.raw.order.campaign && !isDashboardView" class="m9-data-table-align-left order-list-table__dashboard-word-break">{{ elem.item.raw.order.campaign.url }}</div>
				<div v-if="null == elem.item.raw.order.campaign && !isDashboardView">-</div>
			</template>
			
			<template #item.offer_url="elem">
				<div v-if="elem.item.raw.order_items.length > 0 && -1 !== findPublicationIndex(elem.item.raw.order_items)" class="m9-data-table-align-left">
					<div v-if="-1 !== findPublicationIndex(elem.item.raw.order_items) && null != elem.item.raw.order_items[findPublicationIndex(elem.item.raw.order_items)].orderable.offer">{{ elem.item.raw.order_items[findPublicationIndex(elem.item.raw.order_items)].orderable.offer.url }}</div>
					<div v-if="-1 !== findPublicationIndex(elem.item.raw.order_items) && null != elem.item.raw.order_items[findPublicationIndex(elem.item.raw.order_items)].orderable.offer" class="order-list-table__bold">{{ elem.item.raw.order_items[findPublicationIndex(elem.item.raw.order_items)].orderable.offer.name }}</div>
				</div>
				<div v-else>-</div>
			</template>
			
			<template #item.article_title="elem">
				<div v-if="null != elem.item.raw.article" class="order-list-table__article-title" :class="{'order-list-table__dashboard-word-break order-list-table__dashboard-article': isDashboardView}">{{ elem.item.raw.article.title }}</div>
				<div v-else>-</div>
			</template>
			
			<template #item.language="elem">
				<div>
					<MFlag :country-code="changeLanguageIdToFlag(elem.item.raw.article.language_id)" />
				</div>
			</template>
			
			<template #item.type="elem">
				<MTooltip v-if="elem.item.raw.order_items.length > 0" class="order-list-table__order-type-wrapper">
					<template #title>
						<MIcon :icon="setType(elem.item.raw.order_items, false)" />
					</template>
					<template v-slot:content>
						<div>{{ setType(elem.item.raw.order_items, true) }}</div>
					</template>
				</MTooltip>
			</template>
			
			<template #item.owner_email_advertiser="elem">
				<div v-if="isAdvertiser(user)" :class="{'order-list-table__dashboard-word-break': isDashboardView}">
					<div>{{ elem.item.raw.order.user.first_name }} {{ elem.item.raw.order.user.last_name }}</div>
					<div v-if="!isDashboardView">{{ elem.item.raw.order.user.email }}</div>
				</div>
				<div v-if="elem.item.raw.order_items.length > 0 && isModerator(user)">
					<div>{{ elem.item.raw.order.user.first_name }} {{ elem.item.raw.order.user.last_name }}</div>
					<div>{{ elem.item.raw.order.user.email }}</div>
				</div>
			</template>
			
			<template #item.owner_email_publisher="elem">
				<div v-if="elem.item.raw.order_items.length > 0 && -1 !== findPublicationIndex(elem.item.raw.order_items) &&  null != elem.item.raw.order_items[findPublicationIndex(elem.item.raw.order_items)].orderable.offer && isPublisher(user)">
					<div v-if="(elem.item.raw.order_items.length === 1 && elem.item.raw.order_items[0].orderable_type === PUBLICATION_REQUEST) || (-1 !== findPublicationIndex(elem.item.raw.order_items) && elem.item.raw.order_items.length === 2 && elem.item.raw.order_items[findPublicationIndex(elem.item.raw.order_items)].orderable_type === PUBLICATION_REQUEST)">
						<div>{{ elem.item.raw.order_items[findPublicationIndex(elem.item.raw.order_items)].orderable.offer.website_owner.first_name }} {{ elem.item.raw.order_items[findPublicationIndex(elem.item.raw.order_items)].orderable.offer.website_owner.last_name }}</div>
						<div>{{ elem.item.raw.order_items[findPublicationIndex(elem.item.raw.order_items)].orderable.offer.website_owner.email }}</div>
					</div>
				</div>
				<div v-if="elem.item.raw.order_items.length > 0 && isModerator(user)">
					<div v-if="-1 !== findPublicationIndex(elem.item.raw.order_items) && null != elem.item.raw.order_items[findPublicationIndex(elem.item.raw.order_items)].orderable.offer && (elem.item.raw.order_items.length === 1 && elem.item.raw.order_items[0].orderable_type === PUBLICATION_REQUEST) || (-1 !== findPublicationIndex(elem.item.raw.order_items) && elem.item.raw.order_items.length === 2 && elem.item.raw.order_items[findPublicationIndex(elem.item.raw.order_items)].orderable_type === PUBLICATION_REQUEST)">
						<div>{{ elem.item.raw.order_items[findPublicationIndex(elem.item.raw.order_items)].orderable.offer.website_owner.first_name }} {{ elem.item.raw.order_items[findPublicationIndex(elem.item.raw.order_items)].orderable.offer.website_owner.last_name }}</div>
						<div>{{ elem.item.raw.order_items[findPublicationIndex(elem.item.raw.order_items)].orderable.offer.website_owner.email }}</div>
					</div>
				</div>
			</template>
			
			<template #item.links="elem">
				<div v-if="null != elem.item.raw.publication && elem.item.raw.publication.fb_link != null" class="order-list-table__link-wrapper order-list-table__link-wrapper--green">
					<MIcon icon="facebook" height="16" width="16" :color="white" @click="linkRedirect(elem.item.raw.publication.fb_link)" />
				</div>
				<div v-if="elem.item.raw.publication == null || elem.item.raw.publication.fb_link == null" class="order-list-table__link-wrapper">
					<MIcon icon="facebook" height="16" width="16" :color="white" />
				</div>
				<div v-if="null != elem.item.raw.publication && elem.item.raw.publication.url != null" class="order-list-table__link-wrapper order-list-table__link-wrapper--green">
					<MIcon icon="link" height="16" width="16" :color="white" @click="linkRedirect(elem.item.raw.publication.url)" />
				</div>
				<div v-if="elem.item.raw.publication == null || elem.item.raw.publication.url == null" class="order-list-table__link-wrapper">
					<MIcon icon="link" height="16" width="16" :color="white" />
				</div>
			</template>
			
			<template #item.status="elem">
				<div v-if="isAdvertiser(user)" class="order-list-table__status-wrapper">
					<div v-if="elem.item.raw.status === RESIGNED_STATUS" class="order-list-table__status order-list-table__status--red">{{ $t('order.orderFlow.resigned') }}</div>
					<div v-if="elem.item.raw.status === CONTENT_IN_PROGRESS_STATUS" class="order-list-table__status order-list-table__status--yellow">{{ $t('order.orderFlow.inProgress') }}</div>
					<div v-if="elem.item.raw.status === CONTENT_SUBMITTED_STATUS" @click="goToOrder(elem.item.raw.uuid)" @auxclick="goToOrder(elem.item.raw.uuid, true)" class="order-list-table__status order-list-table__status--hover order-list-table__status--bgc-green">{{ $t('order.orderFlow.acceptArticle') }}</div>
					<div v-if="elem.item.raw.status === CONTENT_DISAPPROVED_STATUS" class="order-list-table__status order-list-table__status--red">{{ $t('order.orderFlow.articleDisapproved') }}</div>
					<div v-if="elem.item.raw.status === PUBLICATION_ASSIGNED_STATUS" class="order-list-table__status order-list-table__status--yellow">{{ $t('order.orderFlow.inProgress') }}</div>
					<div v-if="elem.item.raw.status === PUBLICATION_REPORT_COMMENT_STATUS" @click="goToOrder(elem.item.raw.uuid)" @auxclick="goToOrder(elem.item.raw.uuid, true)" class="order-list-table__status order-list-table__status--hover order-list-table__status--bgc-red">{{ $t('order.orderFlow.articleDisapproved') }}</div>
					<div v-if="elem.item.raw.status === PUBLICATION_IN_PROGRESS_STATUS" class="order-list-table__status order-list-table__status--yellow">{{ $t('order.orderFlow.inProgress') }}</div>
					<div v-if="elem.item.raw.status === PUBLICATION_SUBMITTED_STATUS" @click="goToOrder(elem.item.raw.uuid)" @auxclick="goToOrder(elem.item.raw.uuid, true)" class="order-list-table__status order-list-table__status--hover order-list-table__status--bgc-green">{{ $t('order.orderFlow.acceptPublication') }}</div>
					<div v-if="elem.item.raw.status === PUBLICATION_DISAPPROVED_STATUS" class="order-list-table__status order-list-table__status--red">{{ $t('order.orderFlow.publicationDisapproved') }}</div>
					<div v-if="elem.item.raw.status === FINISHED_STATUS" class="order-list-table__status order-list-table__status--green">{{ $t('order.orderFlow.finished') }}</div>
					<div v-if="elem.item.raw.status === SKETCH_STATUS" class="order-list-table__status order-list-table__status--primary">-</div>
					<div v-if="elem.item.raw.status !== SKETCH_STATUS" class="order-list-table__status-line" :class="{
						'order-list-table__status-line--red': elem.item.raw.status === RESIGNED_STATUS || elem.item.raw.status === CONTENT_DISAPPROVED_STATUS || elem.item.raw.status === PUBLICATION_REPORT_COMMENT_STATUS || elem.item.raw.status === PUBLICATION_DISAPPROVED_STATUS,
						'order-list-table__status-line--yellow': elem.item.raw.status === CONTENT_IN_PROGRESS_STATUS || elem.item.raw.status === PUBLICATION_ASSIGNED_STATUS || elem.item.raw.status === PUBLICATION_IN_PROGRESS_STATUS,
						'order-list-table__status-line--green': elem.item.raw.status === CONTENT_SUBMITTED_STATUS || elem.item.raw.status === PUBLICATION_SUBMITTED_STATUS || elem.item.raw.status === FINISHED_STATUS,
					}"
					/>
				</div>
				<div v-if="isPublisher(user)" class="order-list-table__status-wrapper">
					<div v-if="elem.item.raw.status === RESIGNED_STATUS" class="order-list-table__status order-list-table__status--red">{{ $t('order.orderFlow.resigned') }}</div>
					<div v-if="elem.item.raw.status === CONTENT_IN_PROGRESS_STATUS" @click="goToOrder(elem.item.raw.uuid)" @auxclick="goToOrder(elem.item.raw.uuid, true)" class="order-list-table__status order-list-table__status--hover order-list-table__status--bgc-green">{{ $t('order.orderFlow.writeArticle') }}</div>
					<div v-if="elem.item.raw.status === CONTENT_SUBMITTED_STATUS" class="order-list-table__status order-list-table__status--yellow">{{ $t('order.orderFlow.inProgress') }}</div>
					<div v-if="elem.item.raw.status === CONTENT_DISAPPROVED_STATUS" @click="goToOrder(elem.item.raw.uuid)" @auxclick="goToOrder(elem.item.raw.uuid, true)" class="order-list-table__status order-list-table__status--hover order-list-table__status--bgc-red">{{ $t('order.orderFlow.articleDisapproved') }}</div>
					<div v-if="elem.item.raw.status === PUBLICATION_ASSIGNED_STATUS" @click="goToOrder(elem.item.raw.uuid)" @auxclick="goToOrder(elem.item.raw.uuid, true)" class="order-list-table__status order-list-table__status--hover order-list-table__status--bgc-green">{{ $t('order.orderFlow.acceptArticle') }}</div>
					<div v-if="elem.item.raw.status === PUBLICATION_REPORT_COMMENT_STATUS" class="order-list-table__status order-list-table__status--red">{{ $t('order.orderFlow.articleDisapproved') }}</div>
					<div v-if="elem.item.raw.status === PUBLICATION_IN_PROGRESS_STATUS" @click="goToOrder(elem.item.raw.uuid)" @auxclick="goToOrder(elem.item.raw.uuid, true)" class="order-list-table__status order-list-table__status--hover order-list-table__status--bgc-green">{{ $t('order.orderFlow.addLinks') }}</div>
					<div v-if="elem.item.raw.status === PUBLICATION_SUBMITTED_STATUS" class="order-list-table__status order-list-table__status--yellow">{{ $t('order.orderFlow.inProgress') }}</div>
					<div v-if="elem.item.raw.status === PUBLICATION_DISAPPROVED_STATUS" @click="goToOrder(elem.item.raw.uuid)" @auxclick="goToOrder(elem.item.raw.uuid, true)" class="order-list-table__status order-list-table__status--hover order-list-table__status--bgc-red">{{ $t('order.orderFlow.publicationDisapproved') }}</div>
					<div v-if="elem.item.raw.status === FINISHED_STATUS" class="order-list-table__status order-list-table__status--green">{{ $t('order.orderFlow.finished') }}</div>
					<div v-if="elem.item.raw.status === SKETCH_STATUS" class="order-list-table__status order-list-table__status--primary">-</div>
					<div v-if="elem.item.raw.status !== SKETCH_STATUS" class="order-list-table__status-line" :class="{
						'order-list-table__status-line--red': elem.item.raw.status === RESIGNED_STATUS || elem.item.raw.status === CONTENT_DISAPPROVED_STATUS|| elem.item.raw.status === PUBLICATION_REPORT_COMMENT_STATUS || elem.item.raw.status === PUBLICATION_DISAPPROVED_STATUS,
						'order-list-table__status-line--yellow': elem.item.raw.status === CONTENT_SUBMITTED_STATUS || elem.item.raw.status === PUBLICATION_SUBMITTED_STATUS,
						'order-list-table__status-line--green': elem.item.raw.status === CONTENT_IN_PROGRESS_STATUS || elem.item.raw.status === PUBLICATION_ASSIGNED_STATUS || elem.item.raw.status === PUBLICATION_IN_PROGRESS_STATUS || elem.item.raw.status === FINISHED_STATUS,
					}"
					/>
				</div>
				<div v-if="isContentWriter(user)" class="order-list-table__status-wrapper">
					<div v-if="elem.item.raw.status === RESIGNED_STATUS" class="order-list-table__status order-list-table__status--red">{{ $t('order.orderFlow.resigned') }}</div>
					<div v-if="elem.item.raw.status === CONTENT_IN_PROGRESS_STATUS" @click="goToOrder(elem.item.raw.uuid)" @auxclick="goToOrder(elem.item.raw.uuid, true)" class="order-list-table__status order-list-table__status--hover order-list-table__status--bgc-green">{{ $t('order.orderFlow.writeArticle') }}</div>
					<div v-if="elem.item.raw.status === CONTENT_SUBMITTED_STATUS" class="order-list-table__status order-list-table__status--yellow">{{ $t('order.orderFlow.inProgress') }}</div>
					<div v-if="elem.item.raw.status === CONTENT_DISAPPROVED_STATUS" @click="goToOrder(elem.item.raw.uuid)" @auxclick="goToOrder(elem.item.raw.uuid, true)" class="order-list-table__status order-list-table__status--hover order-list-table__status--bgc-red">{{ $t('order.orderFlow.articleDisapproved') }}</div>
					<div v-if="elem.item.raw.status === PUBLICATION_ASSIGNED_STATUS || elem.item.raw.status === PUBLICATION_REPORT_COMMENT_STATUS || elem.item.raw.status === PUBLICATION_IN_PROGRESS_STATUS || elem.item.raw.status === PUBLICATION_SUBMITTED_STATUS || elem.item.raw.status === PUBLICATION_DISAPPROVED_STATUS" class="order-list-table__status order-list-table__status--yellow">{{ $t('order.orderFlow.inProgress') }}</div>
					<div v-if="elem.item.raw.status === FINISHED_STATUS" class="order-list-table__status order-list-table__status--green">{{ $t('order.orderFlow.finished') }}</div>
					<div v-if="elem.item.raw.status === SKETCH_STATUS" class="order-list-table__status order-list-table__status--primary">-</div>
					<div v-if="elem.item.raw.status !== SKETCH_STATUS" class="order-list-table__status-line" :class="{
						'order-list-table__status-line--red': elem.item.raw.status === RESIGNED_STATUS || elem.item.raw.status === CONTENT_DISAPPROVED_STATUS,
						'order-list-table__status-line--yellow': elem.item.raw.status === CONTENT_SUBMITTED_STATUS || elem.item.raw.status === PUBLICATION_ASSIGNED_STATUS || elem.item.raw.status === PUBLICATION_REPORT_COMMENT_STATUS || elem.item.raw.status === PUBLICATION_IN_PROGRESS_STATUS || elem.item.raw.status === PUBLICATION_SUBMITTED_STATUS || elem.item.raw.status === PUBLICATION_DISAPPROVED_STATUS,
						'order-list-table__status-line--green': elem.item.raw.status === CONTENT_IN_PROGRESS_STATUS || elem.item.raw.status === FINISHED_STATUS,
					}"
					/>
				</div>
				<div v-if="isModerator(user)" class="order-list-table__status-wrapper">
					<div v-if="elem.item.raw.status === RESIGNED_STATUS" class="order-list-table__status order-list-table__status--red">{{ $t('order.orderFlow.resigned') }}</div>
					<div v-if="elem.item.raw.status === CONTENT_IN_PROGRESS_STATUS" @click="goToOrder(elem.item.raw.uuid)" @auxclick="goToOrder(elem.item.raw.uuid, true)" class="order-list-table__status order-list-table__status--hover order-list-table__status--bgc-green">{{ $t('order.orderFlow.writeArticle') }}</div>
					<div v-if="elem.item.raw.status === CONTENT_SUBMITTED_STATUS" @click="goToOrder(elem.item.raw.uuid)" @auxclick="goToOrder(elem.item.raw.uuid, true)" class="order-list-table__status order-list-table__status--hover order-list-table__status--bgc-green">{{ $t('order.orderFlow.acceptArticle') }}</div>
					<div v-if="elem.item.raw.status === CONTENT_DISAPPROVED_STATUS" @click="goToOrder(elem.item.raw.uuid)" @auxclick="goToOrder(elem.item.raw.uuid, true)" class="order-list-table__status order-list-table__status--hover order-list-table__status--bgc-red">{{ $t('order.orderFlow.articleDisapproved') }}</div>
					<div v-if="elem.item.raw.status === PUBLICATION_ASSIGNED_STATUS" @click="goToOrder(elem.item.raw.uuid)" @auxclick="goToOrder(elem.item.raw.uuid, true)" class="order-list-table__status order-list-table__status--hover order-list-table__status--bgc-green">{{ $t('order.orderFlow.acceptArticle') }}</div>
					<div v-if="elem.item.raw.status === PUBLICATION_REPORT_COMMENT_STATUS" @click="goToOrder(elem.item.raw.uuid)" @auxclick="goToOrder(elem.item.raw.uuid, true)" class="order-list-table__status order-list-table__status--hover order-list-table__status--bgc-red">{{ $t('order.orderFlow.articleDisapproved') }}</div>
					<div v-if="elem.item.raw.status === PUBLICATION_IN_PROGRESS_STATUS" @click="goToOrder(elem.item.raw.uuid)" @auxclick="goToOrder(elem.item.raw.uuid, true)" class="order-list-table__status order-list-table__status--hover order-list-table__status--bgc-green">{{ $t('order.orderFlow.addLinks') }}</div>
					<div v-if="elem.item.raw.status === PUBLICATION_SUBMITTED_STATUS" @click="goToOrder(elem.item.raw.uuid)" @auxclick="goToOrder(elem.item.raw.uuid, true)" class="order-list-table__status order-list-table__status--hover order-list-table__status--bgc-green">{{ $t('order.orderFlow.acceptPublication') }}</div>
					<div v-if="elem.item.raw.status === PUBLICATION_DISAPPROVED_STATUS" @click="goToOrder(elem.item.raw.uuid)" @auxclick="goToOrder(elem.item.raw.uuid, true)" class="order-list-table__status order-list-table__status--hover order-list-table__status--bgc-red">{{ $t('order.orderFlow.publicationDisapproved') }}</div>
					<div v-if="elem.item.raw.status === FINISHED_STATUS" class="order-list-table__status order-list-table__status--green">{{ $t('order.orderFlow.finished') }}</div>
					<div v-if="elem.item.raw.status === SKETCH_STATUS" class="order-list-table__status order-list-table__status--primary">-</div>
					<div v-if="elem.item.raw.status !== SKETCH_STATUS" class="order-list-table__status-line" :class="{
						'order-list-table__status-line--red': elem.item.raw.status === RESIGNED_STATUS || elem.item.raw.status === CONTENT_DISAPPROVED_STATUS || elem.item.raw.status === PUBLICATION_REPORT_COMMENT_STATUS || elem.item.raw.status === PUBLICATION_DISAPPROVED_STATUS,
						'order-list-table__status-line--green': elem.item.raw.status === CONTENT_IN_PROGRESS_STATUS || elem.item.raw.status === CONTENT_SUBMITTED_STATUS  || elem.item.raw.status === PUBLICATION_ASSIGNED_STATUS || elem.item.raw.status === PUBLICATION_IN_PROGRESS_STATUS || elem.item.raw.status === PUBLICATION_SUBMITTED_STATUS || elem.item.raw.status === FINISHED_STATUS,
					}"
					/>
				</div>
			</template>
			
			<template #item.created_at="elem">
				<div v-if="null != elem.item.raw.created_at">{{ dateFormat(elem.item.raw.created_at) }}</div>
				<div v-if="null != elem.item.raw.link_added_at" class="order-list-table__green">{{ dateFormat(elem.item.raw.link_added_at) }}</div>
				<div v-else>-</div>
			</template>
			
			<template #item.publication_started_at="elem">
				<div v-if="null != elem.item.raw.publication_started_at">{{ dateFormatWithTime(elem.item.raw.publication_started_at) }}</div>
				<div v-else>-</div>
			</template>
			
			<template #item.payment_at="elem">
				<div v-if="null != elem.item.raw.payment_at">{{ dateFormatWithTime(elem.item.raw.payment_at) }}</div>
				<div v-else>-</div>
			</template>
			
			<template #item.article_received_at="elem">
				<div v-if="null != elem.item.raw.article_received_at">{{ dateFormatWithTime(elem.item.raw.article_received_at) }}</div>
				<div v-else>-</div>
			</template>
			
			<template #item.article_accepted_at="elem">
				<div v-if="null != elem.item.raw.article_accepted_at">{{ dateFormatWithTime(elem.item.raw.article_accepted_at) }}</div>
				<div v-else>-</div>
			</template>
			
			<template #item.link_added_at="elem">
				<div v-if="null != elem.item.raw.link_added_at">{{ dateFormatWithTime(elem.item.raw.link_added_at) }}</div>
				<div v-else>-</div>
			</template>
			
			<template #item.article_price="elem">
				<div v-if="elem.item.raw.order_items.length > 0 && !isPublisher(user) && null != elem.item.raw.order_items[0].price" class="m9-data-table-align-price order-list-table__price" :class="{'order-list-table__dashboard-price': isDashboardView}">{{ elem.item.raw.order_items[0].orderable_type === ARTICLE_REQUEST ? (elem.item.raw.order_items[0].price / 100).toFixed(2) + ' ' + changeCurrencyIdToSymbol(elem.item.raw.order.currency_id) : elem.item.raw.order_items.length === 2 && elem.item.raw.order_items[1].orderable_type === ARTICLE_REQUEST ? (elem.item.raw.order_items[1].price / 100).toFixed(2) + ' ' + changeCurrencyIdToSymbol(elem.item.raw.order.currency_id) : '0.00' + ' ' + changeCurrencyIdToSymbol(elem.item.raw.order.currency_id) }}</div>
				<div v-if="elem.item.raw.order_items.length > 0 && isPublisher(user) && null != elem.item.raw.order_items[0].price" class="m9-data-table-align-price order-list-table__price">{{ elem.item.raw.order_items[0].orderable_type === ARTICLE_REQUEST ? (elem.item.raw.order_items[0].price / 100).toFixed(2) + ' ' + userCurrencySymbol : elem.item.raw.order_items.length === 2 && elem.item.raw.order_items[1].orderable_type === ARTICLE_REQUEST ? (elem.item.raw.order_items[1].price / 100).toFixed(2) + ' ' + userCurrencySymbol : '0.00' + ' ' + userCurrencySymbol }}</div>
			</template>
			
			<template #item.base_price="elem">
				<div v-if="elem.item.raw.order_items.length > 0 && isPublisher(user) && null != elem.item.raw.order_items[0].base_price" class="m9-data-table-align-price order-list-table__price">{{ elem.item.raw.order_items[0].orderable_type === PUBLICATION_REQUEST ? (elem.item.raw.order_items[0].base_price / 100).toFixed(2) + ' ' + userCurrencySymbol : elem.item.raw.order_items.length === 2 && elem.item.raw.order_items[1].orderable_type === PUBLICATION_REQUEST ? (elem.item.raw.order_items[1].base_price / 100).toFixed(2) + ' ' + userCurrencySymbol : '0.00' + ' ' + userCurrencySymbol }}</div>
			</template>
			
			<template #item.publication_price="elem">
				<div v-if="elem.item.raw.order_items.length > 0 && !isPublisher(user) && null != elem.item.raw.order_items[0].price" class="m9-data-table-align-price order-list-table__price" :class="{'order-list-table__dashboard-price': isDashboardView}">{{ elem.item.raw.order_items[0].orderable_type === ARTICLE_REQUEST ? (elem.item.raw.order_items[0].price / 100).toFixed(2) + ' ' + changeCurrencyIdToSymbol(elem.item.raw.order.currency_id) : elem.item.raw.order_items.length === 2 && elem.item.raw.order_items[1].orderable_type === ARTICLE_REQUEST ? (elem.item.raw.order_items[1].price / 100).toFixed(2) + ' ' + changeCurrencyIdToSymbol(elem.item.raw.order.currency_id) : '0.00' + ' ' + changeCurrencyIdToSymbol(elem.item.raw.order.currency_id) }}</div>
				<div v-if="elem.item.raw.order_items.length > 0 && !isPublisher(user) && null != elem.item.raw.order_items[0].price" class="m9-data-table-align-price order-list-table__price" :class="{'order-list-table__dashboard-price order-list-table__dashboard-price--green': isDashboardView}">{{ elem.item.raw.order_items[0].orderable_type === PUBLICATION_REQUEST ? (elem.item.raw.order_items[0].price / 100).toFixed(2) + ' ' + changeCurrencyIdToSymbol(elem.item.raw.order.currency_id) : elem.item.raw.order_items.length === 2 && elem.item.raw.order_items[1].orderable_type === PUBLICATION_REQUEST ? (elem.item.raw.order_items[1].price / 100).toFixed(2) + ' ' + changeCurrencyIdToSymbol(elem.item.raw.order.currency_id) : '0.00' + ' ' + changeCurrencyIdToSymbol(elem.item.raw.order.currency_id) }}</div>
			</template>
			
			<template #item.actions="elem">
				<div class="order-list-table__action-wrapper">
					<MTooltip without-btn>
						<template v-slot:title>
							<MIcon icon="clipboard" :color="white" @click="goToOrder(elem.item.raw.uuid)" @auxclick="goToOrder(elem.item.raw.uuid, true)" class="order-list-table__action-icon" />
						</template>
						<template v-slot:content>
							<div>{{ $t('order.orderFlow.details') }}</div>
						</template>
					</MTooltip>
					<div class="order-list-table__notes-count" v-if="null != elem.item.raw.notes_count && elem.item.raw.notes_count > 0">{{ elem.item.raw.notes_count }}</div>
				</div>
			</template>
			
			<template #footer.prepend>
				<div class="order-list-table__csv-btn">
					<MTooltip without-btn>
						<template v-slot:title>
							<MIcon id="csv-btn" icon="csv-file" width="20" height="20" @click="downloadToCsv" />
						</template>
						<template v-slot:content>
							<div>{{ $t('components.downloadCsv') }}</div>
						</template>
					</MTooltip>
				</div>
			</template>
			
			<template #bottom v-if="isDashboardView && items.length > 0">
				<div></div>
			</template>
		</v-data-table-server>
	</div>
</template>

<script setup lang="ts">
import { VDataTableServer } from 'vuetify/labs/VDataTable';
import { ref, watch, defineEmits, defineProps } from 'vue';
import router from '@/router';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/stores/user';
import { useTableSettingsStore } from '@/stores/tableSettings';
import { useSessionStore } from '@/stores/session';
import { useDictionaryStore } from '@/stores/dictionary';
import AgencyApi from '@/api/v1/AgencyApi';
import OrderApi from '@/api/v2/OrderApi';
import CampaignApi from '@/api/v1/CampaignApi';
import { toast } from 'vue3-toastify';
import variables from '@/assets/scss/modules/variables.module.scss';
import {
	CONTENT_IN_PROGRESS_STATUS,
	PUBLICATION_ASSIGNED_STATUS,
	FINISHED_STATUS,
	RESIGNED_STATUS,
	CONTENT_SUBMITTED_STATUS,
	CONTENT_DISAPPROVED_STATUS,
	PUBLICATION_IN_PROGRESS_STATUS,
	PUBLICATION_SUBMITTED_STATUS,
	PUBLICATION_REPORT_COMMENT_STATUS,
	PUBLICATION_DISAPPROVED_STATUS,
	SKETCH_STATUS,
	ORDER_TYPE_ARTICLE_FILTER
} from '@/hooks/OrderFlowHooks';
import { itemsPerPageOptions } from '@/hooks/TableSettingsHooks';
import { ARTICLE_REQUEST, PUBLICATION_REQUEST } from '@/hooks/OrderHooks';
import { ROUTE_DASHBOARD } from '@/hooks/RoutingHooks';
import { clearTableSettings, editTableSettings, ORDER_GROUPS_LIST } from '@/hooks/TableSettingsHooks';
import {
	isAdvertiser,
	isContentWriter,
	isPublisher,
	isModerator,
} from '@/hooks/UserHooks';
import { exportTableToCsv } from '@/hooks/ExportCsvHook';
import { dateFormatWithTime, dateFormat } from '@/hooks/DataHooks';
import type { TableSettingsInterface, TableSettingsRequestInterface } from '@/models/TableSettingsModel';
import type { IOrderGroupDetails, IOrderGroupTableItemOrderItems } from '@/models/OrderModel';
import type { ITableOptions, TableHeaderInterface } from '@/models/VuetifyModel';
import type { CampaignInterface } from '@/models/CampaignModel';
import type { AgencyItemListInterface } from '@/models/AgencyModel';
import MFlag from '@/components/atoms/MFlag.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MTooltip from '@/components/atoms/MTooltip.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import TableSettingsMenuWithModal from '@/components/organisms/TableSettingsMenuWithModal.vue';
import OrderListSummary from '@/views/Orders/components/OrderListSummary.vue';

interface IOwnProps {
	isOutdatedOrders?: boolean
}
interface IProps extends IOwnProps {
}

const props = defineProps<IProps>();

const agencyApi = new AgencyApi();
const orderApi = new OrderApi();
const campaignApi = new CampaignApi();

const { locale } = storeToRefs(useSessionStore());
const { languages, currencies } = useDictionaryStore();
const { white } = variables;
const { user, userCurrencySymbol } = storeToRefs(useUserStore());
const { t } = useI18n();
const { setSort, setHeaders, setFilters, setPagination } = useTableSettingsStore();
const emit = defineEmits(['setPublicationsCounter', 'setOutdatedPublications']);

const loading = ref<boolean>(false);
const items = ref<Array<IOrderGroupDetails>|any>([]);
const total = ref<number>(0);
const currentPage = ref<number>(0);
const lastPage = ref<number>(0);
const campaign = ref<string|null>(null);
const dateTo = ref<string|null>(null);
const dateFrom = ref<string|null>(null);
let headers = ref<Array<TableHeaderInterface>|any>([]);
let firstLoadPage:boolean = true;
let isClearAction:boolean = false;
let isFilterAction:boolean = false;
const search = ref<string|null>(null);
const searchTimeout = ref<any>(undefined);
const isClearedTableSettingsAction = ref<boolean>(false);
const campaigns = ref<Array<CampaignInterface>|any>([]);
const languageId = ref<number|null>(null);
const status = ref<string|null>(null);
const statusList = ref<Array<any>>([
	{
		id: 0,
		name: `${t('order.orderFlow.contentInProgress')}`,
		slug: CONTENT_IN_PROGRESS_STATUS,
	},
	{
		id: 1,
		name: `${t('order.orderFlow.contentSubmitted')}`,
		slug: CONTENT_SUBMITTED_STATUS,
	},
	{
		id: 2,
		name: `${t('order.orderFlow.contentDisapproved')}`,
		slug: CONTENT_DISAPPROVED_STATUS,
	},
	{
		id: 3,
		name: `${t('order.orderFlow.publicationAssigned')}`,
		slug: PUBLICATION_ASSIGNED_STATUS,
	},
	{
		id: 4,
		name: `${t('order.orderFlow.publicationInProgress')}`,
		slug: PUBLICATION_IN_PROGRESS_STATUS,
	},
	{
		id: 5,
		name: `${t('order.orderFlow.publicationReportComment')}`,
		slug: PUBLICATION_REPORT_COMMENT_STATUS,
	},
	{
		id: 6,
		name: `${t('order.orderFlow.publicationSubmitted')}`,
		slug: PUBLICATION_SUBMITTED_STATUS,
	},
	{
		id: 7,
		name: `${t('order.orderFlow.publicationDisapprovedStatus')}`,
		slug: PUBLICATION_DISAPPROVED_STATUS,
	},
	{
		id: 8,
		name: `${t('order.orderFlow.finished')}`,
		slug: FINISHED_STATUS,
	},
	{
		id: 9,
		name: `${t('order.orderFlow.resigned')}`,
		slug: RESIGNED_STATUS,
	},
]);
const options = ref<ITableOptions|any>({
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	groupBy: [],
	search: null,
});
const isDashboardView = ref<boolean>(false);
if (null != user.value && isAdvertiser(user.value) && router.currentRoute.value.name === ROUTE_DASHBOARD) {
	isDashboardView.value = true;
}

const localeLanguage = ref<string>('');

if ('us' === locale.value) {
	localeLanguage.value = 'en';
} else if (locale.value != null) {
	localeLanguage.value = locale.value;
} else {
	localeLanguage.value = 'pl';
}

const defaultHeaders = () => {
	const result = [
		{
			title: !isDashboardView.value ? `${t('dashboard.campaignName')}` : `${t('dashboard.campaignsName')}`,
			align: 'center',
			sortable: !isDashboardView.value,
			key: 'campaign',
			width: !isDashboardView.value ? '250px' : '',
		},
	];
	
	if (!isDashboardView.value) {
		result.push(
			{
				title: `${t('dashboard.url')}`,
				align: 'center',
				sortable: true,
				key: 'offer_url',
				width: '300px'
			},
		);
	}
	
	result.push(...[
		{
			title: `${t('order.orderFlow.articleTitle')}`,
			align: 'center',
			sortable: !isDashboardView.value,
			key: 'article_title',
			width: !isDashboardView.value ? '200px' : '',
		},
	]);
	
	if (null != user.value && isModerator(user.value)) {
		result.push(
			{
				title: `${t('publications.advertiser')}`,
				align: 'center',
				sortable: true,
				key: 'owner_email_advertiser',
				width: '160px'
			},
			{
				title: `${t('publications.publisher')}`,
				align: 'center',
				sortable: true,
				key: 'owner_email_publisher',
				width: '160px'
			}
		);
	}
	
	if (null != user.value && user.value?.is_agency && isAdvertiser(user.value)) {
		result.push(
			{
				title: `${t('publications.advertiser')}`,
				align: 'center',
				sortable: !isDashboardView.value,
				key: 'owner_email_advertiser',
				width: !isDashboardView.value ? '160px' : '',
			},
		);
	}
	
	if (null != user.value && user.value?.is_agency && isPublisher(user.value)) {
		result.push(
			{
				title: `${t('publications.publisher')}`,
				align: 'center',
				sortable: true,
				key: 'owner_email_publisher',
				width: '160px'
			},
		);
	}
	
	if (!isDashboardView.value) {
		result.push(...[
			{
				title: `${t('dashboard.links')}`,
				align: 'center',
				sortable: false,
				key: 'links',
				width: '60px'
			},
			{
				title: `${t('dashboard.status')}`,
				align: 'center',
				sortable: true,
				key: 'status',
				width: '200px'
			},
		]);
	}
	
	if (null != user.value && (isPublisher(user.value) || isModerator(user.value))) {
		result.push(
			{
				title: `${t('dashboard.createdAt')}`,
				align: 'center',
				sortable: true,
				key: 'publication_started_at',
				width: '110px'
			},
		);
	}
	
	if (null != user.value && !isPublisher(user.value) && !isModerator(user.value) && !isDashboardView.value) {
		result.push(
			{
				title: `${t('order.orderFlow.createdAt')}`,
				align: 'center',
				sortable: true,
				key: 'created_at',
				width: '110px'
			},
		);
	}
	
	if (null != user.value && !isPublisher(user.value) && !isContentWriter(user.value)) {
		result.push(
			{
				title: `${t('order.orderFlow.publicationPrice')}`,
				align: 'center',
				sortable: !isDashboardView.value,
				key: 'publication_price',
				width: !isDashboardView.value ? '130px' : '',
			},
		);
	}
	
	if (null != user.value && isPublisher(user.value)) {
		result.push(...[
			{
				title: `${t('order.orderFlow.publicationPricePublisher')}`,
				align: 'center',
				sortable: !isDashboardView.value,
				key: 'base_price',
				width: !isDashboardView.value ? '130px' : '',
			},
		]);
	}
	
	if (null != user.value && isContentWriter(user.value)) {
		result.push(...[
			{
				title: `${t('order.orderFlow.articlePrice')}`,
				align: 'center',
				sortable: !isDashboardView.value,
				key: 'article_price',
				width: !isDashboardView.value ? '130px' : '',
			},
		]);
	}
	
	if (!isDashboardView.value) {
		result.push(
			{
				title: `${t('dashboard.actions')}`,
				align: 'center',
				sortable: false,
				key: 'actions',
				width: '80px'
			},
		);
	}
	
	return result;
};
headers.value = defaultHeaders();
const defaultInactiveHeaders = () => {
	const result:Array<TableHeaderInterface> = [
		{
			title: `${t('order.orderFlow.language')}`,
			align: 'center',
			sortable: false,
			key: 'language',
			width: '100px'
		},
	];
	
	if (null != user.value && isModerator(user.value)) {
		result.push(
			{
				title: `${t('order.orderFlow.type')}`,
				align: 'center',
				sortable: false,
				key: 'type',
				width: '100px'
			},
		);
	}
	
	if (null != user.value && isModerator(user.value)) {
		result.push(
			{
				title: `${t('order.orderFlow.startedAt')}`,
				align: 'center',
				sortable: true,
				key: 'payment_at',
				width: '110px'
			},
			{
				title: `${t('order.orderFlow.articleReceivedAt')}`,
				align: 'center',
				sortable: true,
				key: 'article_received_at',
				width: '110px'
			},
			{
				title: `${t('order.orderFlow.articleAcceptedAt')}`,
				align: 'center',
				sortable: true,
				key: 'article_accepted_at',
				width: '110px'
			},
			{
				title: `${t('order.orderFlow.linkAddedAt')}`,
				align: 'center',
				sortable: true,
				key: 'link_added_at',
				width: '110px'
			},
		);
	}
	
	return result;
};

const dependentAccountUuid = ref<string|null>(null);
const dependentAccountList = ref<Array<AgencyItemListInterface>|any>([]);
const agencyDependentAccountList = async() => {
	if (null == user.value) {
		return;
	}
	try {
		const result = await agencyApi.agencyUserList(user.value.uuid);
		if (!result.isSuccess) {
			return;
		}
		dependentAccountList.value = result.payload.data;
		dependentAccountList.value.push(user.value);
	} catch (e) {
		return;
	}
};
if (user.value?.is_agency && !isDashboardView.value) {
	agencyDependentAccountList();
}
const orderedCampaignsList = async() => {
	try {
		const result = await campaignApi.getCampaigns(true);
		if (!result.isSuccess) {
			return;
		}
		campaigns.value = result.payload.data;
		campaigns.value.unshift({
			id: null,
			created_at: null,
			description: null,
			name: `${t('components.noCampaign')}`,
			old_id: null,
			updated_at: null,
			url: null,
			user_id: null,
			uuid: 'null',
			suggested_websites: [],
		});
	} catch (e) {
		return;
	}
};
orderedCampaignsList();

const orderGroupList = async() => {
	loading.value = true;
	if (null == user.value) {
		toast.error(`${t('order.orderFlow.toast.errorGetOrderGroupList')}`);
		loading.value = false;
		return;
	}
	if (!props.isOutdatedOrders) {
		try {
			const result = await orderApi.orderGroupList(
				!isDashboardView.value ? options.value.page : 1,
				!isDashboardView.value ? options.value.itemsPerPage : 6,
				options.value.sortBy.length > 0 ? options.value.sortBy[0].key : null,
				options.value.sortBy.length > 0 ? options.value.sortBy[0].order : null,
				'' !== search.value ? search.value : null,
				campaign.value,
				dependentAccountUuid.value,
				dateTo.value,
				dateFrom.value,
				languageId.value,
				null,
				status.value,
				isPublisher(user.value) || isModerator(user.value),
				false,
				false,
			);
			if (!result.isSuccess) {
				toast.error(`${t('order.orderFlow.toast.errorGetOrderGroupList')}`);
				loading.value = false;
				return;
			}
			currentPage.value = result.payload.current_page;
			lastPage.value = result.payload.last_page;
			total.value = result.payload.total;
			items.value = result.payload.data;
			
			// Wyjątek na życzenie klienta, schować jedno zamówienia danemu userowi
			if (null != user.value && user.value.id === 1997) {
				const orderToHide = result.payload.data.find((elem: any) => elem.uuid === '4c3315d1-0014-4c6d-8f23-b78f1e0b0b21');
				if (null != orderToHide) {
					const index = result.payload.data.indexOf(orderToHide);
					items.value.splice(index, 1);
				}
			}
			if (null != user.value && user.value.id === 867) {
				const orderToHide = result.payload.data.find((elem: any) => elem.uuid === '63a14e76-02a6-49bd-a152-50a710602e56');
				if (null != orderToHide) {
					const index = result.payload.data.indexOf(orderToHide);
					items.value.splice(index, 1);
				}
			}
			if (null != user.value && user.value.id === 827) {
				const orderToHide = result.payload.data.find((elem: any) => elem.uuid === '349ff2d6-7b3e-45aa-bae8-3434c1e30216');
				if (null != orderToHide) {
					const index = result.payload.data.indexOf(orderToHide);
					items.value.splice(index, 1);
				}
			}
			if (null != user.value && user.value.id === 867) {
				const orderToHide = result.payload.data.find((elem: any) => elem.uuid === '59b7a4ec-e3bc-46d5-9755-f9a0667b96a9');
				if (null != orderToHide) {
					const index = result.payload.data.indexOf(orderToHide);
					items.value.splice(index, 1);
				}
			}
			if (null != user.value && user.value.id === 2523) {
				const orderToHide = result.payload.data.find((elem: any) => elem.uuid === 'fb1d410f-135c-4ab5-b247-4e050eb0a426');
				if (null != orderToHide) {
					const index = result.payload.data.indexOf(orderToHide);
					items.value.splice(index, 1);
				}
			}
			if (null != user.value && user.value.id === 1997) {
				const orderToHide = result.payload.data.find((elem: any) => elem.uuid === '30463a2d-83f9-4af1-a4cd-6f1526364c20');
				if (null != orderToHide) {
					const index = result.payload.data.indexOf(orderToHide);
					items.value.splice(index, 1);
				}
			}
			if (null != user.value && user.value.id === 1997) {
				const orderToHide = result.payload.data.find((elem: any) => elem.uuid === '41156ce6-ebd9-4a63-a2ec-3cf143eeed00');
				if (null != orderToHide) {
					const index = result.payload.data.indexOf(orderToHide);
					items.value.splice(index, 1);
				}
			}
			if (null != user.value && user.value.id === 6442) {
				const orderToHide = result.payload.data.find((elem: any) => elem.uuid === '40b9f09d-2e58-4c19-ad13-828c17028547');
				if (null != orderToHide) {
					const index = result.payload.data.indexOf(orderToHide);
					items.value.splice(index, 1);
				}
			}
			if (null != user.value && user.value.id === 1997) {
				const orderToHide = result.payload.data.find((elem: any) => elem.uuid === '28efb73d-6cb2-4eb3-b22d-f60f4acda93f');
				if (null != orderToHide) {
					const index = result.payload.data.indexOf(orderToHide);
					items.value.splice(index, 1);
				}
			}
			if (null != user.value && user.value.id === 1997) {
				const orderToHide = result.payload.data.find((elem: any) => elem.uuid === 'e2566a95-384d-45e2-b4c8-0616fc91ae21');
				if (null != orderToHide) {
					const index = result.payload.data.indexOf(orderToHide);
					items.value.splice(index, 1);
				}
			}
			if (null != user.value && user.value.id === 827) {
				const orderToHide = result.payload.data.find((elem: any) => elem.uuid === 'f5efe56e-6a6c-4725-8e5c-874426fc5698');
				if (null != orderToHide) {
					const index = result.payload.data.indexOf(orderToHide);
					items.value.splice(index, 1);
				}
			}
			if (null != user.value && user.value.id === 827) {
				const orderToHide = result.payload.data.find((elem: any) => elem.uuid === 'ab6c529e-39c1-49ce-877b-233d45730281');
				if (null != orderToHide) {
					const index = result.payload.data.indexOf(orderToHide);
					items.value.splice(index, 1);
				}
			}
			
			if (null != user.value && isModerator(user.value) && router.currentRoute.value.name === ROUTE_DASHBOARD) {
				emit('setPublicationsCounter', total.value);
			}
		} catch (e) {
			toast.error(`${t('order.orderFlow.toast.errorGetOrderGroupList')}`);
			loading.value = false;
			return;
		}
	} else {
		try {
			const result = await orderApi.orderGroupList(
				options.value.page,
				options.value.itemsPerPage,
				options.value.sortBy.length > 0 ? options.value.sortBy[0].key : null,
				options.value.sortBy.length > 0 ? options.value.sortBy[0].order : null,
				'' !== search.value ? search.value : null,
				campaign.value,
				dependentAccountUuid.value,
				dateTo.value,
				dateFrom.value,
				languageId.value,
				null,
				status.value,
				isPublisher(user.value) || isModerator(user.value),
				props.isOutdatedOrders,
				isDashboardView.value,
			);
			if (!result.isSuccess) {
				toast.error(`${t('order.orderFlow.toast.errorGetOrderGroupList')}`);
				loading.value = false;
				return;
			}
			currentPage.value = result.payload.current_page;
			lastPage.value = result.payload.last_page;
			total.value = result.payload.total;
			items.value = result.payload.data;
			
			// Wyjątek na życzenie klienta, schować jedno zamówienia danemu userowi
			if (null != user.value && user.value.id === 1997) {
				const orderToHide = result.payload.data.find((elem: any) => elem.uuid === '4c3315d1-0014-4c6d-8f23-b78f1e0b0b21');
				if (null != orderToHide) {
					const index = result.payload.data.indexOf(orderToHide);
					items.value.splice(index, 1);
				}
			}
			if (null != user.value && user.value.id === 867) {
				const orderToHide = result.payload.data.find((elem: any) => elem.uuid === '63a14e76-02a6-49bd-a152-50a710602e56');
				if (null != orderToHide) {
					const index = result.payload.data.indexOf(orderToHide);
					items.value.splice(index, 1);
				}
			}
			if (null != user.value && user.value.id === 827) {
				const orderToHide = result.payload.data.find((elem: any) => elem.uuid === '349ff2d6-7b3e-45aa-bae8-3434c1e30216');
				if (null != orderToHide) {
					const index = result.payload.data.indexOf(orderToHide);
					items.value.splice(index, 1);
				}
			}
			if (null != user.value && user.value.id === 867) {
				const orderToHide = result.payload.data.find((elem: any) => elem.uuid === '59b7a4ec-e3bc-46d5-9755-f9a0667b96a9');
				if (null != orderToHide) {
					const index = result.payload.data.indexOf(orderToHide);
					items.value.splice(index, 1);
				}
			}
			if (null != user.value && user.value.id === 2523) {
				const orderToHide = result.payload.data.find((elem: any) => elem.uuid === 'fb1d410f-135c-4ab5-b247-4e050eb0a426');
				if (null != orderToHide) {
					const index = result.payload.data.indexOf(orderToHide);
					items.value.splice(index, 1);
				}
			}
			if (null != user.value && user.value.id === 1997) {
				const orderToHide = result.payload.data.find((elem: any) => elem.uuid === '30463a2d-83f9-4af1-a4cd-6f1526364c20');
				if (null != orderToHide) {
					const index = result.payload.data.indexOf(orderToHide);
					items.value.splice(index, 1);
				}
			}
			if (null != user.value && user.value.id === 1997) {
				const orderToHide = result.payload.data.find((elem: any) => elem.uuid === '41156ce6-ebd9-4a63-a2ec-3cf143eeed00');
				if (null != orderToHide) {
					const index = result.payload.data.indexOf(orderToHide);
					items.value.splice(index, 1);
				}
			}
			
			if (null != user.value && isModerator(user.value) && router.currentRoute.value.name === ROUTE_DASHBOARD) {
				emit('setPublicationsCounter', total.value);
			}
		} catch (e) {
			toast.error(`${t('order.orderFlow.toast.errorGetOrderGroupList')}`);
			loading.value = false;
			return;
		}
	}
	loading.value = false;
};

const orderGroupsTableSettings = ref<TableSettingsInterface|null>(null);
const initTableOptions = async() => {
	let isShouldBeSaved = false;
	orderGroupsTableSettings.value = storeToRefs(useTableSettingsStore())[ORDER_GROUPS_LIST].value;
	
	// Columns options
	if (null == orderGroupsTableSettings.value) {
		await setSort({
			name: ORDER_GROUPS_LIST,
			sortBy: 'updated_at',
			sortDesc: 'desc',
		});
		isShouldBeSaved = true;
	}
	// Headers options
	if (null == orderGroupsTableSettings.value || 0 === orderGroupsTableSettings.value.columns.active.length || orderGroupsTableSettings.value.filters.locale !== localeLanguage.value) {
		isClearedTableSettingsAction.value = true;
		await setHeaders({
			name: ORDER_GROUPS_LIST,
			headers: {
				active: defaultHeaders(),
				inactive: defaultInactiveHeaders(),
			}
		});
		isShouldBeSaved = true;
	}
	// Pagination options
	if (null == orderGroupsTableSettings.value || null == orderGroupsTableSettings.value.page || null == orderGroupsTableSettings.value.per_page) {
		await setPagination({
			name: ORDER_GROUPS_LIST,
			page: 1,
			perPage: 10
		});
		isShouldBeSaved = true;
	}
	
	// Filters options
	// if (null != orderGroupsTableSettings.value.filters.search) {
	// 	search.value = orderGroupsTableSettings.value.filters.search;
	// }
	// if (null != orderGroupsTableSettings.value.filters.campaign) {
	// 	campaign.value = orderGroupsTableSettings.value.filters.campaign;
	// }
	// if (null != orderGroupsTableSettings.value.filters.agencyAccount) {
	// 	dependentAccountUuid.value = orderGroupsTableSettings.value.filters.agencyAccount;
	// }
	// if (null != orderGroupsTableSettings.value.filters.languageId) {
	// 	languageId.value = orderGroupsTableSettings.value.filters.languageId;
	// }
	// if (null != orderGroupsTableSettings.value.filters.status) {
	// 	status.value = orderGroupsTableSettings.value.filters.status;
	// }
	if (null != orderGroupsTableSettings.value && orderGroupsTableSettings.value.filters.locale !== localeLanguage.value) {
		const filters = {
			search: search.value,
			agencyAccount: dependentAccountUuid.value,
			campaign: campaign.value,
			locale: localeLanguage.value,
		};
		await setFilters({
			name: ORDER_GROUPS_LIST,
			filters: filters
		});
	}
	
	if (null != orderGroupsTableSettings.value) {
		options.value.page = orderGroupsTableSettings.value.page;
		options.value.itemsPerPage = orderGroupsTableSettings.value.per_page;
		options.value.sortBy.push({
			key: orderGroupsTableSettings.value.sort_column,
			order: orderGroupsTableSettings.value.sort_order,
		});
		
		headers.value = orderGroupsTableSettings.value.columns.active;
	}
	
	if (isShouldBeSaved) {
		await editTableSettingsAction();
	}
	if (null != user.value && !isAdvertiser(user.value)) {
		await orderGroupList();
	}
	isClearedTableSettingsAction.value = false;
};
if (!isDashboardView.value) {
	initTableOptions();
} else {
	orderGroupList();
}

const editTableSettingsAction = () => {
	if (null != user.value && null != orderGroupsTableSettings.value) {
		const tableSettings: TableSettingsRequestInterface = {
			sort_column: null != orderGroupsTableSettings.value.sort_column && 0 < orderGroupsTableSettings.value.sort_column.length ? orderGroupsTableSettings.value.sort_column : null,
			sort_order: null != orderGroupsTableSettings.value.sort_order && 0 < orderGroupsTableSettings.value.sort_order.length ? orderGroupsTableSettings.value.sort_order : null,
			page: orderGroupsTableSettings.value.page,
			per_page: orderGroupsTableSettings.value.per_page,
			filters: JSON.stringify(orderGroupsTableSettings.value.filters),
			columns: JSON.stringify(orderGroupsTableSettings.value.columns),
		};
		editTableSettings(ORDER_GROUPS_LIST, tableSettings, user.value?.active_role_id);
	}
};

const clearTableSettingsAction = async() => {
	isClearAction = true;
	
	search.value = null;
	dependentAccountUuid.value = null;
	campaign.value = null;
	languageId.value = null;
	status.value = null;
	
	options.value.page = 1;
	options.value.itemsPerPage = 10;
	options.value.sortBy = [];
	
	headers.value = defaultHeaders();
	
	const filters = {
		search: search.value,
		agencyAccount: dependentAccountUuid.value,
		campaign: campaign.value,
		languageId: languageId.value,
		status: status.value,
		locale: localeLanguage.value,
	};
	
	const columns = {
		active: defaultHeaders(),
		inactive: defaultInactiveHeaders(),
	};
	
	const tableSettings:TableSettingsRequestInterface = {
		sort_column: 'updated_at',
		sort_order: 'desc',
		page: 1,
		per_page: 10,
		filters: JSON.stringify(filters),
		columns: JSON.stringify(columns),
	}
	
	if (null != user.value) {
		await clearTableSettings(ORDER_GROUPS_LIST, tableSettings, user.value?.active_role_id, `${t('components.toast.errorClearTableSettings')}`, `${t('components.toast.successClearTableSettings')}`);
	}
	isClearedTableSettingsAction.value = true;
	
	setTimeout(() => {
		isClearAction = false;
		isClearedTableSettingsAction.value = false;
		orderGroupList();
	},1);
};

const optionsUpdate = async(val:ITableOptions) => {
	options.value.page = val.page;
	options.value.itemsPerPage = val.itemsPerPage;
	options.value.search = val.search;
	options.value.groupBy = val.groupBy;
	options.value.sortBy = val.sortBy;
	
	if (!firstLoadPage && !isClearAction) {
		await setPagination({
			name: ORDER_GROUPS_LIST,
			page: val.page,
			perPage: val.itemsPerPage,
		});
		await setSort({
			name: ORDER_GROUPS_LIST,
			sortBy: 0 < val.sortBy.length ? val.sortBy[0].key : [],
			sortDesc: 0 < val.sortBy.length ? val.sortBy[0].order : [],
		});
		if (!isFilterAction) {
			await editTableSettingsAction();
			await orderGroupList();
		}
	}
	isFilterAction = false;
	firstLoadPage = false;
};

watch(([dependentAccountUuid, campaign, search, languageId, status]), async (newVal, oldVal) => {
	if (!isClearAction) {
		if (options.value.page > 1) {
			options.value.page = 1;
			await setPagination({
				name: ORDER_GROUPS_LIST,
				page: 1,
				perPage: options.value.itemsPerPage,
			});
			isFilterAction = true;
		}
		if (newVal[0] !== oldVal[0] || newVal[1] !== oldVal[1] || newVal[2] !== oldVal[2] || newVal[3] !== oldVal[3] || newVal[4] !== oldVal[4]) {
			clearTimeout(searchTimeout.value);
			searchTimeout.value = setTimeout(async () => {
				const filters = {
					search: newVal[2],
					agencyAccount: newVal[0],
					campaign: newVal[1],
					languageId: newVal[3],
					status: newVal[4],
					locale: localeLanguage.value
				};
				await setFilters({
					name: ORDER_GROUPS_LIST,
					filters: filters
				});
				await editTableSettingsAction();
				await orderGroupList();
			}, 700);
		}
		if (newVal[4] === ORDER_TYPE_ARTICLE_FILTER) {
			if (
				status.value !== CONTENT_IN_PROGRESS_STATUS &&
				status.value !== CONTENT_SUBMITTED_STATUS &&
				status.value !== CONTENT_DISAPPROVED_STATUS &&
				status.value !== FINISHED_STATUS &&
				status.value !== RESIGNED_STATUS
			) {
				status.value = null;
			}
			statusList.value = [
				{
					id: 0,
					name: `${t('order.orderFlow.contentInProgress')}`,
					slug: CONTENT_IN_PROGRESS_STATUS,
				},
				{
					id: 1,
					name: `${t('order.orderFlow.contentSubmitted')}`,
					slug: CONTENT_SUBMITTED_STATUS,
				},
				{
					id: 2,
					name: `${t('order.orderFlow.contentDisapproved')}`,
					slug: CONTENT_DISAPPROVED_STATUS,
				},
				{
					id: 8,
					name: `${t('order.orderFlow.finished')}`,
					slug: FINISHED_STATUS,
				},
				{
					id: 9,
					name: `${t('order.orderFlow.resigned')}`,
					slug: RESIGNED_STATUS,
				},
			];
		} else {
			statusList.value = [
				{
					id: 0,
					name: `${t('order.orderFlow.contentInProgress')}`,
					slug: CONTENT_IN_PROGRESS_STATUS,
				},
				{
					id: 1,
					name: `${t('order.orderFlow.contentSubmitted')}`,
					slug: CONTENT_SUBMITTED_STATUS,
				},
				{
					id: 2,
					name: `${t('order.orderFlow.contentDisapproved')}`,
					slug: CONTENT_DISAPPROVED_STATUS,
				},
				{
					id: 3,
					name: `${t('order.orderFlow.publicationAssigned')}`,
					slug: PUBLICATION_ASSIGNED_STATUS,
				},
				{
					id: 4,
					name: `${t('order.orderFlow.publicationInProgress')}`,
					slug: PUBLICATION_IN_PROGRESS_STATUS,
				},
				{
					id: 5,
					name: `${t('order.orderFlow.publicationReportComment')}`,
					slug: PUBLICATION_REPORT_COMMENT_STATUS,
				},
				{
					id: 6,
					name: `${t('order.orderFlow.publicationSubmitted')}`,
					slug: PUBLICATION_SUBMITTED_STATUS,
				},
				{
					id: 7,
					name: `${t('order.orderFlow.publicationDisapprovedStatus')}`,
					slug: PUBLICATION_DISAPPROVED_STATUS,
				},
				{
					id: 8,
					name: `${t('order.orderFlow.finished')}`,
					slug: FINISHED_STATUS,
				},
				{
					id: 9,
					name: `${t('order.orderFlow.resigned')}`,
					slug: RESIGNED_STATUS,
				},
			];
		}
	}
},
{
	deep: true,
});

const headersMoveAction = () => {
	if (null != orderGroupsTableSettings.value) {
		headers.value = orderGroupsTableSettings.value.columns.active;
	}
};
const linkRedirect = (url:string) => {
	window.open(url, '_blank');
};
const goToOrder = (orderGroupUuid:string, isAuxClick?: boolean) => {
	if (isAuxClick) {
		window.open(`/order/${orderGroupUuid}`, '_blank');
	} else {
		router.push(`/order/${orderGroupUuid}`);
	}
};

const findPublicationIndex = (orderItems:Array<IOrderGroupTableItemOrderItems>) => {
	const publication = orderItems.find(elem => elem.orderable_type === PUBLICATION_REQUEST);
	if (null != publication) {
		const index = orderItems.indexOf(publication);
		return index;
	}
	return -1;
};

const setType = (orderItems:Array<IOrderGroupTableItemOrderItems>, isReturnName:boolean):any => {
	if (orderItems.length === 1 && orderItems[0].orderable_type === ARTICLE_REQUEST) {
		return isReturnName ? `${t('order.orderFlow.onlyArticle')}` : 'box-open';
	}
	if (orderItems.length === 1 && orderItems[0].orderable_type === PUBLICATION_REQUEST) {
		return isReturnName ? `${t('order.orderFlow.own')}` : 'pen-fancy';
	}
	if (orderItems.length === 2) {
		const index:number|null = findPublicationIndex(orderItems);
		if (null != index && orderItems[index].orderable.is_article_write_by_publisher === true) {
			return isReturnName ? `${t('order.orderFlow.writtenByPublisher')}` : 'articles';
		}
		if (null != index && orderItems[index].orderable.is_article_write_by_publisher === false) {
			return isReturnName ? `${t('order.orderFlow.byM9')}` : 'projects';
		}
	}
	return isReturnName ? `${t('order.orderFlow.onlyArticle')}` : 'articles';
};

const changeLanguageIdToFlag = (languageId:number) => {
	const language = languages.find(elem => elem.id === languageId);
	if (null == language) {
		return '';
	}
	return language.flag;
};
const changeCurrencyIdToSymbol = (currencyId:number) => {
	const currency = currencies.find(elem => elem.id === currencyId);
	if (null == currency) {
		return null;
	}
	return currency.currency_symbol;
};

const emitedDates = (to:string, from:string) => {
	dateTo.value = to;
	dateFrom.value = from;
	// Ten emit odpala za pierwszym razem liste
	orderGroupList();
};

const statusDashboardColor = (status:string) => {
	switch (status) {
		case RESIGNED_STATUS:
		case CONTENT_DISAPPROVED_STATUS:
		case PUBLICATION_REPORT_COMMENT_STATUS:
		case PUBLICATION_DISAPPROVED_STATUS:
			return 'order-list-table__dashboard-red';
		case CONTENT_IN_PROGRESS_STATUS:
		case PUBLICATION_ASSIGNED_STATUS:
		case PUBLICATION_IN_PROGRESS_STATUS:
			return 'order-list-table__dashboard-yellow';
		case CONTENT_SUBMITTED_STATUS:
		case PUBLICATION_SUBMITTED_STATUS:
		case FINISHED_STATUS:
			return 'order-list-table__dashboard-green';
	}
};

const downloadToCsv = () => {
	let rowKeys: string[] = [];
	if (null != user.value) {
		if (isAdvertiser(user.value)) {
			rowKeys = ['campaign', 'offer_url', 'article_title', 'links', 'status', 'created_at', 'publication_price'];
		}
		if (isAdvertiser(user.value) && user.value.is_agency === 1) {
			rowKeys = ['campaign', 'offer_url', 'article_title', 'owner_email_advertiser', 'links', 'status', 'created_at', 'publication_price'];
		}
		if (isPublisher(user.value)) {
			rowKeys = ['campaign', 'offer_url', 'article_title', 'links', 'status', 'publication_started_at', 'base_price'];
		}
		if (isPublisher(user.value) && user.value.is_agency === 1) {
			rowKeys = ['campaign', 'offer_url', 'article_title', 'owner_email_publisher', 'links', 'status', 'publication_started_at', 'base_price'];
		}
		if (isContentWriter(user.value)) {
			rowKeys = ['campaign', 'offer_url', 'article_title', 'links', 'status', 'created_at', 'article_price'];
		}
		if (isModerator(user.value)) {
			rowKeys = ['campaign', 'offer_url', 'article_title', 'owner_email_advertiser', 'owner_email_publisher', 'links', 'status', 'publication_started_at', 'payment_at', 'article_received_at', 'article_accepted_at', 'link_added_at', 'publication_price'];
		}
	}
	exportTableToCsv(headers.value, items.value, rowKeys, `${t('components.toast.errorDownloadCsv')}`, `${t('components.toast.successDownloadCsv')}`, t, 'orders');
};
</script>

<style scoped lang="scss">
.order-list-table {
	@import '@/assets/scss/vuetifyDataTable';
	&__loading {
		opacity: 0.5;
	}
	
	&__flag-wrapper {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 5px;
	}
	
	&__order-type-wrapper {
		width: max-content;
		margin: 0 auto;
	}
	
	&__article-title {
		font-size: 12px;
		text-align: left;
	}
	
	&__link-wrapper {
		margin: 5px auto;
		width: max-content;
		background-color: $grey-300;
		border-radius: 8px;
		padding: 7px 8px 3px;
		
		&--green {
			margin-top: 5px;
			margin-bottom: 5px;
			background-color: $green-400;
			cursor: pointer;
			transition: opacity 0.3s;
			
			&:hover {
				opacity: 0.5;
			}
		}
	}
	
	&__status-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
	}
	
	&__status {
		color: $white-100;
		font-weight: 700;
		width: max-content;
		border-radius: 8px;
		padding: 10px 15px;
		margin: auto;
		
		&--hover {
			cursor: pointer;
			transition: opacity 0.3s;
			
			&:hover {
				opacity: 0.5;
			}
		}

		&--bgc-green {
			background-color: $green-400;
		}

		&--bgc-yellow {
			background-color: $yellow-500;
		}

		&--bgc-red {
			background-color: $secondary-400;
		}
		
		&--red {
			color: $secondary-400;
		}
		
		&--yellow {
			color: $yellow-500;
		}
		
		&--green {
			color: $green-400;
		}
		
		&--primary {
			color: $primary-400;
		}
	}
	
	&__status-line {
		width: 100%;
		height: 3px;
		background-color: $green-400;
		
		&--red {
			background-color: $secondary-400;
		}
		
		&--yellow {
			background-color: $yellow-500;
		}
		
		&--green {
			background-color: $green-400;
		}
	}
	
	&__action-wrapper {
		position: relative;
		width: max-content;
		margin: 0 auto;
		background-color: $grey-400;
		padding: 8px 9px 5px 10px;
		border-radius: 50%;
		
		&:deep(.m-btn) {
			padding: 10px 6px 10px 10px !important;
			border-radius: 50% !important;
		}
	}
	
	&__action-icon {
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__notes-count {
		position: absolute;
		top: -13px;
		right: -16px;
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $secondary-400;
		color: $white-100;
		border-radius: 50%;
	}
	
	&__top-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 15px;
		margin: 10px 0 0;
		padding: 15px;
	}
	
	&__search-wrapper {
		width: 250px;
		
		&:deep(.v-input__details) {
			display: none;
		}
	}
	
	&__user-wrapper {
		min-width: 240px;
		display: flex;
		align-items: center;
		gap: 5px;
		
		&:deep(.v-input__details) {
			display: none;
		}
		
		&:deep(.m-autocomplete) {
			width: 100%;
		}
	}
	
	&__campaign-wrapper {
		min-width: 240px;
		display: flex;
		align-items: center;
		gap: 5px;
		
		&:deep(.v-input__details) {
			display: none;
		}
		
		&:deep(.m-autocomplete) {
			width: 100%;
		}
	}
	
	&__label {
		font-size: $size-14;
		color: $grey-400;
		font-weight: 700;
		
		&--min-content {
			min-width: max-content;
		}
	}
	
	&__table-settings-wrapper {
		margin-left: auto;
	}
	
	&__bold {
		font-weight: 600;
	}
	
	&__dashboard-word-break {
		word-break: break-all;
	}
	
	&__dashboard-article {
		margin-top: 0;
	}
	
	&__mod-origin-status {
		margin-top: 7px;
		color: $primary-400;
	}
	
	&__price {
		width: calc(50% + 25px);
		text-align: end;
	}
	
	&__dashboard-price {
		margin-left: 0;
		
		&--green {
			color: $green-400;
		}
	}
	
	&__dashboard {
		position: relative;
		margin-left: 12px;
		
		&-red {
			&:before {
				position: absolute;
				content: '';
				top: 50%;
				left: -12px;
				width: 4px;
				height: 80%;
				min-height: 32px;
				background-color: $secondary-400;
				border-top-left-radius: 8px;
				border-bottom-left-radius: 8px;
				transform: translate(0%, -50%);
			}
		}
		
		&-yellow {
			&:before {
				position: absolute;
				content: '';
				top: 50%;
				left: -12px;
				width: 4px;
				height: 80%;
				min-height: 32px;
				background-color: $yellow-500;
				border-top-left-radius: 8px;
				border-bottom-left-radius: 8px;
				transform: translate(0%, -50%);
			}
		}
		
		&-green {
			&:before {
				position: absolute;
				content: '';
				top: 50%;
				left: -12px;
				width: 4px;
				height: 80%;
				min-height: 32px;
				background-color: $green-400;
				border-top-left-radius: 8px;
				border-bottom-left-radius: 8px;
				transform: translate(0%, -50%);
			}
		}
	}
	
	&__dashboard-btn-wrapper {
		display: flex;
		justify-content: center;
		padding: 21px 0;
	}
	
	&__green {
		color: $green-400;
	}
	
	&__csv-btn {
		margin-left: 10px;
		margin-right: auto;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
}

.order-list-table-dashboard {
	@import '@/assets/scss/vuetifyDataTableDashboard';
	overflow-y: scroll;
	background-color: $white-100;
	border-radius: 8px;
	
	&:deep(.v-data-table) {
		@include media-breakpoint-up(xxl) {
			height: 342px + 95px + 9px;
		}
	}
}
</style>
