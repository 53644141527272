import { toast } from 'vue3-toastify';
import router from '@/router';
import { ROUTE_OFFER_LIST, ROUTE_ORDER_LIST, ROUTE_ORDER_GROUP_LIST } from '@/hooks/RoutingHooks';
import { APPROVED, DISAPPROVED, RESUBMITTED_MODERATION, SKETCH, SUBMITTED_MODERATION } from '@/hooks/WebsitesHooks';
import {
	ARTICLE_ID_PREMIUM,
	ARTICLE_ID_STANDARD,
	ARTICLE_ID_TECHNICAL,
	ARTICLE_REQUEST,
	DEPOSIT_REQUEST,
	PUBLICATION_REQUEST
} from '@/hooks/OrderHooks';
import { dateFormatWithTime, dateFormat } from '@/hooks/DataHooks';
import type { TableHeaderInterface } from '@/models/VuetifyModel';
import {
	CONTENT_DISAPPROVED_STATUS,
	CONTENT_IN_PROGRESS_STATUS,
	CONTENT_SUBMITTED_STATUS,
	FINISHED_STATUS,
	PUBLICATION_ASSIGNED_STATUS,
	PUBLICATION_DISAPPROVED_STATUS,
	PUBLICATION_IN_PROGRESS_STATUS,
	PUBLICATION_REPORT_COMMENT_STATUS,
	PUBLICATION_SUBMITTED_STATUS,
	RESIGNED_STATUS
} from '@/hooks/OrderFlowHooks';

const getHeadersForCsv = (headers: TableHeaderInterface[]) => {
	return headers.filter(header => header.key !== 'actions' && header.key !== 'data-table-expand').map((header: TableHeaderInterface) => header.title);
};

const formatRowForCsv = (row: any, rowKeys: any[], translatorObject?: any) => {
	// Mapuj wartości według kolejności kluczy
	return rowKeys.map(key => {
		let value = row[key] ?? '';
		// Tutaj wyjatki poniewaz na widoku listy ofert incomes sa arrayka oraz publication finished musza byc laczona wartoscia
		if (router.currentRoute.value.name === ROUTE_OFFER_LIST) {
			if (key === 'incomes' && row[key].length > 0) {
				value = (+row.incomes[0].total_earn / 100).toFixed(2);
			}
			if (key === 'new_publications_finished') {
				value =
					null != row.new_publications_finished && null != row.publications_finished
					? +row.new_publications_finished + +row.publications_finished
					: null == row.new_publications_finished && null != row.publications_finished
					? +row.publications_finished
					: null != row.new_publications_finished && null == row.publications_finished
					? +row.new_publications_finished
					: 0;
			}
			if (key === 'duration_id') {
				value = translatorObject(`dictionary.period.${row.duration_id}`);
			}
			if (key === 'status') {
				switch (row.status) {
					case APPROVED:
						value = translatorObject('offers.approved');
						break;
					case DISAPPROVED:
						value = translatorObject('offers.disapproved');
						break;
					case SUBMITTED_MODERATION:
						value = translatorObject('offers.submittedModeration');
						break;
					case RESUBMITTED_MODERATION:
						value = translatorObject('offers.resubmittedModeration');
						break;
					case SKETCH:
						value = translatorObject('offers.sketch');
						break;
					default:
						value = row.status;
						break;
				}
			}
		}

		if (router.currentRoute.value.name === ROUTE_ORDER_LIST) {
			if (key === 'details') {
				if (row.orderable_type === PUBLICATION_REQUEST) {
					value = row.orderable.url;
				} else if (row.orderable_type === ARTICLE_REQUEST) {
					value = row.orderable.article_type_id === ARTICLE_ID_STANDARD
						? translatorObject('order.list.standard')
						: row.orderable.article_type_id === ARTICLE_ID_TECHNICAL
						? translatorObject('order.list.technical')
						: row.orderable.article_type_id === ARTICLE_ID_PREMIUM
						? translatorObject('order.list.premium')
						: row.orderable.article_type_id;
				} else {
					value = '-';
				}
			}

			if (key === 'orderable_type') {
				switch (row.orderable_type) {
					case PUBLICATION_REQUEST:
						value = translatorObject('order.list.publication');
						break;
					case ARTICLE_REQUEST:
						value = translatorObject('order.list.article');
						break;
					case DEPOSIT_REQUEST:
						value = translatorObject('order.list.deposit');
						break;
					default:
						value = row.orderable_type;
						break;
				}
			}

			// Tworze takie cos poniewaz w tej tabeli sa 2x exporty i jeden zawiera w obiekcie element orderable a drugi nie i tak samo z items  aby je rozroznic
			// orderable dla csv 1
			if (key === 'net' && 'orderable' in row) {
				value = (+row.gross / 100).toFixed(2);
			}
			// items dla csv 2
			if (key === 'net' && 'items' in row) {
				value = (+row.net / 100).toFixed(2);
			}
			if (key === 'tax' && 'items' in row) {
				value = (+row.tax / 100).toFixed(2);
			}
			if (key === 'gross' && 'items' in row) {
				value = (+row.gross / 100).toFixed(2);
			}
			if (key === 'campaign_id' && 'items' in row) {
				value = null != row.campaign && row.campaign.name !== 'Domyślna' ? row.campaign.name : '-';
			}

			if (key === 'created_at') {
				value = dateFormatWithTime(row.created_at);
			}
		}

		if (router.currentRoute.value.name === ROUTE_ORDER_GROUP_LIST) {
			if (key === 'campaign') {
				value = null != row.order.campaign ? row.order.campaign.name : '-';
			}
			if (key === 'offer_url') {
				const publication = row.order_items.find(elem => elem.orderable_type === PUBLICATION_REQUEST);
				if (publication) {
					value = publication.orderable.offer.url;
				} else {
					value = '';
				}
			}
			if (key === 'article_title') {
				value = null != row.article ? row.article.title : '-';
			}
			if (key === 'owner_email_advertiser') {
				value = row.order.user.email;
			}
			if (key === 'links') {
				value = null != row.publication && null != row.publication.url && null != row.publication.fb_link ? row.publication.url + ' / ' + row.publication.fb_link : null != row.publication && null != row.publication.url ? row.publication.url : '';
			}
			if (key === 'status') {
				switch (row.status) {
					case CONTENT_IN_PROGRESS_STATUS:
						value = translatorObject('order.orderFlow.contentInProgress');
						break;
					case CONTENT_SUBMITTED_STATUS:
						value = translatorObject('order.orderFlow.contentSubmitted');
						break;
					case CONTENT_DISAPPROVED_STATUS:
						value = translatorObject('order.orderFlow.contentDisapproved');
						break;
					case PUBLICATION_ASSIGNED_STATUS:
						value = translatorObject('order.orderFlow.publicationAssigned');
						break;
					case PUBLICATION_IN_PROGRESS_STATUS:
						value = translatorObject('order.orderFlow.publicationInProgress');
						break;
					case PUBLICATION_REPORT_COMMENT_STATUS:
						value = translatorObject('order.orderFlow.publicationReportComment');
						break;
					case PUBLICATION_SUBMITTED_STATUS:
						value = translatorObject('order.orderFlow.publicationSubmitted');
						break;
					case PUBLICATION_DISAPPROVED_STATUS:
						value = translatorObject('order.orderFlow.publicationDisapprovedStatus');
						break;
					case FINISHED_STATUS:
						value = translatorObject('order.orderFlow.finished');
						break;
					case RESIGNED_STATUS:
						value = translatorObject('order.orderFlow.resigned');
						break;
					default:
						value = row.orderable_type;
						break;
				}
			}
		}
		if (key === 'created_at') {
			if (null != row.link_added_at) {
				value = dateFormat(row.created_at) + ' / ' + dateFormat(row.link_added_at);
			} else {
				value = dateFormat(row.created_at);
			}
		}
		if (key === 'publication_price') {
			if (row.order_items.length > 1) {
				value = ((row.order_items[0].price + row.order_items[1].price) / 100).toFixed(2);
			}
			if (row.order_items.length === 1) {
				value = (row.order_items[0].price / 100).toFixed(2);
			}
		}
		if (key === 'owner_email_publisher') {
			const publication = row.order_items.find(elem => elem.orderable_type === PUBLICATION_REQUEST);
			if (publication) {
				value = publication.orderable.offer.website_owner.email;
			} else {
				value = '';
			}
		}
		if (key === 'publication_started_at') {
			if (null != row.publication_started_at) {
				value = dateFormat(row.publication_started_at);
			} else {
				value = '-';
			}
		}
		if (key === 'base_price') {
			const publication = row.order_items.find(elem => elem.orderable_type === PUBLICATION_REQUEST);
			if (publication) {
				value = (publication.orderable.offer.price / 100).toFixed(2);
			}
		}
		if (key === 'article_price') {
			const article = row.order_items.find(elem => elem.orderable_type === ARTICLE_REQUEST);
			if (article) {
				value = (article.price / 100).toFixed(2);
			}
		}
		if (key === 'payment_at') {
			if (null != row.payment_at) {
				value = dateFormat(row.payment_at);
			} else {
				value = '-';
			}
		}
		if (key === 'article_received_at') {
			if (null != row.article_received_at) {
				value = dateFormat(row.article_received_at);
			} else {
				value = '-';
			}
		}
		if (key === 'article_accepted_at') {
			if (null != row.article_accepted_at) {
				value = dateFormat(row.article_accepted_at);
			} else {
				value = '-';
			}
		}
		if (key === 'link_added_at') {
			if (null != row.link_added_at) {
				value = dateFormat(row.link_added_at);
			} else {
				value = '-';
			}
		}

		if (value == null || (Array.isArray(value) && value.length === 0)) {
			value = '';
		}

		// Konwersja na string i escapowanie znaków specjalnych
		const stringValue = String(value);
		if (stringValue.includes(',') || stringValue.includes('"') || stringValue.includes('\n')) {
			return `"${stringValue.replace(/"/g, '""')}"`;
		}
		return stringValue;
	});
};

export const exportTableToCsv = (headers: TableHeaderInterface[], rows: any[], rowKeys: any[], errorToast: string, successToast: string, translatorObject: any, fileName?: string) => {
	if (rows.length === 0) {
		toast.error(errorToast);
		return;
	}

	// Create CSV content
	const csvHeaders = getHeadersForCsv(headers);
	let csvContent = csvHeaders.join(',') + '\n';
	// Add rows
	rows.forEach((row: any) => {
		const formattedRow = formatRowForCsv(row, rowKeys, translatorObject);
		csvContent += formattedRow.join(',') + '\n';
	});

	// Handle special characters and escape commas
	csvContent = csvContent.replace(/"/g, '""');
	// Create and trigger download
	const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
	const link = document.createElement('a');
	const url = URL.createObjectURL(blob);

	link.setAttribute('href', url);
	link.setAttribute('download', `${fileName}_${new Date().toISOString().split('T')[0] + '-' + new Date().toISOString().split('T')[1].split('.')[0]}.csv`);
	link.style.visibility = 'hidden';

	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);

	toast.success(successToast);
};
