<template>
	<div class="publisher-to-do-actions">
		<div class="publisher-to-do-actions__title" @click="handleRedirect(selectedToDoItems)">{{ $t('dashboard.toDo') }}</div>
		
		<div v-if="null != user && ((isPublisher(user) && (0 < domainItems.length || 0 < orderItems.length)) || (isAdvertiser(user) && 0 < orderItems.length))" class="publisher-to-do-actions__top-btn-wrapper">
			<div class="publisher-to-do-actions__top-btn-item vuetify-btn-elevation" :class="{'publisher-to-do-actions__top-btn-item--active': selectedToDoItems === ORDER_TO_DO_TYPE}" @click="handleChangeToDoList(ORDER_TO_DO_TYPE)">
				<div class="publisher-to-do-actions__top-btn-text">{{ $t('dashboard.orders') }}</div>
				<div v-if="orderItems.length > 0 && isPublisher(user)" class="publisher-to-do-actions__notification-dot"></div>
			</div>
			<div v-if="isPublisher(user)" class="publisher-to-do-actions__top-btn-item vuetify-btn-elevation" :class="{'publisher-to-do-actions__top-btn-item--active': selectedToDoItems === WEBSITES_TO_DO_TYPE}" @click="handleChangeToDoList(WEBSITES_TO_DO_TYPE)">
				<div class="publisher-to-do-actions__top-btn-text">{{ $t('dashboard.websites') }}</div>
				<div v-if="domainItems.length > 0" class="publisher-to-do-actions__notification-dot"></div>
			</div>
			
			<div class="publisher-to-do-actions__question-wrapper">
				<MIcon icon="question" />
				
				<div class="publisher-to-do-actions__question-tooltip-wrapper">
					<div class="publisher-to-do-actions__question-tooltip-title">{{ $t('dashboard.tooltipStatusInfo') }}</div>
					<div class="publisher-to-do-actions__question-tooltip-item publisher-to-do-actions__question-tooltip-item--red">{{ $t('dashboard.rejected') }}</div>
					<div class="publisher-to-do-actions__question-tooltip-item publisher-to-do-actions__question-tooltip-item--yellow">{{ $t('dashboard.reports') }}</div>
					<div class="publisher-to-do-actions__question-tooltip-item publisher-to-do-actions__question-tooltip-item--green">{{ $t('dashboard.toApprove') }}</div>
				</div>
			</div>
		</div>
		
		<div
			class="publisher-to-do-actions__wrapper"
			:class="{
				'publisher-to-do-actions__wrapper--border': (selectedToDoItems === WEBSITES_TO_DO_TYPE && 0 === domainItems.length) || (selectedToDoItems === ORDER_TO_DO_TYPE && 0 === orderItems.length),
				'publisher-to-do-actions__wrapper--border-top': 0 === domainItems.length && 0 === orderItems.length,
			}"
			ref="scrollBox"
			@scroll="handleScroll"
		>
			<div v-if="null != user && ((isPublisher(user) && 0 === domainItems.length && 0 === orderItems.length) || (isAdvertiser(user) && 0 === orderItems.length))" class="publisher-to-do-actions__plug-wrapper">
				<MIcon icon="tick" width="24" height="24" :color="green400" />
				<div class="publisher-to-do-actions__plug-text">{{ $t('dashboard.completedAllTasks') }}</div>
			</div>
			<div v-if="null != user && ((isPublisher(user) && (0 < domainItems.length || 0 < orderItems.length)) || (isAdvertiser(user) && 0 < orderItems.length))" class="publisher-to-do-actions__action-wrapper">
				<div v-if="selectedToDoItems === WEBSITES_TO_DO_TYPE">
					<div v-if="isPublisher(user) && 0 === domainItems.length" class="publisher-to-do-actions__plug-wrapper">
						<MIcon icon="tick" width="24" height="24" :color="green400" />
						<div class="publisher-to-do-actions__plug-text">{{ $t('dashboard.completedAllTasks') }}</div>
					</div>
					<div v-if="isPublisher(user) && 0 < domainItems.length">
						<div v-for="item in domainItems" :key="item.uuid" class="publisher-to-do-actions__item-wrapper">
							<div class="publisher-to-do-actions__dot" :class="{
								'publisher-to-do-actions__dot--red': (item.type === WEBSITE_TYPE && item.status === DISAPPROVED) || (item.type === WEBSITE_OFFER_TYPE && item.status === DISAPPROVED)
							}"></div>
							<div v-if="item.type === WEBSITE_TYPE && item.status === DISAPPROVED" class="publisher-to-do-actions__text-wrapper">
								<div>{{ $t('dashboard.domain') }} <span>{{ item.name }}</span> {{ $t('dashboard.hasBeenReject') }}</div>
							</div>
							<div v-if="item.type === WEBSITE_OFFER_TYPE && item.status === DISAPPROVED" class="publisher-to-do-actions__text-wrapper">
								<div>{{ $t('dashboard.offer') }} <span>{{ item.name }}</span> {{ $t('dashboard.belongsToDomain') }} <span>{{ item.website_name }}</span> {{ $t('dashboard.hasBeenReject') }}</div>
							</div>
							<div class="publisher-to-do-actions__btn-wrapper">
								<MTooltip v-if="item.type === WEBSITE_TYPE && item.status === DISAPPROVED" without-btn :max-width="309.5">
									<template v-slot:title>
										<div class="publisher-to-do-actions__btn-action vuetify-btn-elevation" @click="goToEditWebsite(item.uuid)">
											<MIcon icon="arrow-out" :color="white" />
										</div>
									</template>
									<template v-slot:content>
										<div>{{ $t('dashboard.do') }}</div>
									</template>
								</MTooltip>
								<MTooltip v-if="item.type === WEBSITE_OFFER_TYPE && item.status === DISAPPROVED" without-btn :max-width="309.5">
									<template v-slot:title>
										<div class="publisher-to-do-actions__btn-action vuetify-btn-elevation" @click="goToEditOffer(item.uuid)">
											<MIcon icon="arrow-out" :color="white" />
										</div>
									</template>
									<template v-slot:content>
										<div>{{ $t('dashboard.do') }}</div>
									</template>
								</MTooltip>
							</div>
						</div>
					</div>
				</div>
				
				<div v-if="selectedToDoItems === ORDER_TO_DO_TYPE">
					<div v-if="0 === orderItems.length" class="publisher-to-do-actions__plug-wrapper">
						<MIcon icon="tick" width="24" height="24" :color="green400" />
						<div class="publisher-to-do-actions__plug-text">{{ $t('dashboard.completedAllTasks') }}</div>
					</div>
					<div v-if="0 < orderItems.length">
						<div v-for="item in orderItems" :key="item.uuid" class="publisher-to-do-actions__item-wrapper">
							<div class="publisher-to-do-actions__dot" :class="{
								'publisher-to-do-actions__dot--red': (isPublisher(user) && (item.status === RESIGNED_STATUS)) || (isAdvertiser(user) && (item.status === RESIGNED_STATUS)),
								'publisher-to-do-actions__dot--yellow': (isPublisher(user) && (item.status === CONTENT_DISAPPROVED_STATUS || item.status === PUBLICATION_DISAPPROVED_STATUS)) || (isAdvertiser(user) && (item.status === PUBLICATION_REPORT_COMMENT_STATUS)),
								'publisher-to-do-actions__dot--green': (isPublisher(user) && (item.status === CONTENT_IN_PROGRESS_STATUS || item.status === PUBLICATION_ASSIGNED_STATUS || item.status === PUBLICATION_IN_PROGRESS_STATUS)) || (isAdvertiser(user) && (item.status === CONTENT_SUBMITTED_STATUS || item.status === PUBLICATION_SUBMITTED_STATUS)),
							}"></div>
							<div v-if="null != item.name_publication" class="publisher-to-do-actions__text-wrapper">
								<div v-if="(isPublisher(user) && (item.status === CONTENT_DISAPPROVED_STATUS || item.status === PUBLICATION_DISAPPROVED_STATUS)) || (isAdvertiser(user) && (item.status === PUBLICATION_REPORT_COMMENT_STATUS))">{{ $t('dashboard.orderReportComments') }} <span>{{ item.name_publication }}</span></div>
								<div v-if="(isPublisher(user) && (item.status === CONTENT_IN_PROGRESS_STATUS || item.status === PUBLICATION_ASSIGNED_STATUS || item.status === PUBLICATION_IN_PROGRESS_STATUS)) || (isAdvertiser(user) && (item.status === CONTENT_SUBMITTED_STATUS || item.status === PUBLICATION_SUBMITTED_STATUS))">{{ $t('dashboard.order') }} <span>{{ item.name_publication }}</span> {{ $t('dashboard.waitingForAction') }}</div>
							</div>
							<div v-if="null == item.name_publication && null != item.name_article">
								<div v-if="(isPublisher(user) && (item.status === CONTENT_DISAPPROVED_STATUS || item.status === PUBLICATION_DISAPPROVED_STATUS)) || (isAdvertiser(user) && (item.status === PUBLICATION_REPORT_COMMENT_STATUS))">{{ $t('dashboard.orderReportComments') }} {{ $t('dashboard.article') }}<span>{{ item.name_article }}</span></div>
								<div v-if="(isPublisher(user) && (item.status === CONTENT_IN_PROGRESS_STATUS || item.status === PUBLICATION_ASSIGNED_STATUS || item.status === PUBLICATION_IN_PROGRESS_STATUS)) || (isAdvertiser(user) && (item.status === CONTENT_SUBMITTED_STATUS || item.status === PUBLICATION_SUBMITTED_STATUS))">{{ $t('dashboard.order') }} {{ $t('dashboard.waitingForAction') }} <span>{{ item.name_article }}</span> {{ $t('dashboard.waitingForAction') }}</div>
							</div>
							<div class="publisher-to-do-actions__btn-wrapper">
								<MTooltip without-btn :max-width="309.5">
									<template v-slot:title>
										<div class="publisher-to-do-actions__btn-action vuetify-btn-elevation" @click="goToOrder(item.uuid)">
											<MIcon
												icon="arrow-out"
												:color="white"
											/>
										</div>
									</template>
									<template v-slot:content>
										<div>{{ $t('dashboard.do') }}</div>
									</template>
								</MTooltip>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="publisher-to-do-actions__bottom-blank"></div>
		<MProgressBar v-show="loading" indeterminate height="2" />
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { toast } from 'vue3-toastify';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import variables from '@/assets/scss/modules/variables.module.scss';
import { DISAPPROVED } from '@/hooks/WebsitesHooks';
import { WEBSITE_OFFER_TYPE, WEBSITE_TYPE, WEBSITES_TO_DO_TYPE, ORDER_TO_DO_TYPE } from '@/hooks/DashboardActionItemsHooks';
import { isAdvertiser, isPublisher } from '@/hooks/UserHooks';
import type { IActionItemsItem, IActionOrdersItemsItem } from '@/models/DashboardPublisherModel';
import DashboardApi from '@/api/v1/DashboardApi';
import MTooltip from '@/components/atoms/MTooltip.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MProgressBar from '@/components/atoms/MProgressBar.vue';
import {
	CONTENT_DISAPPROVED_STATUS,
	CONTENT_IN_PROGRESS_STATUS,
	CONTENT_SUBMITTED_STATUS,
	PUBLICATION_ASSIGNED_STATUS,
	PUBLICATION_DISAPPROVED_STATUS,
	PUBLICATION_IN_PROGRESS_STATUS,
	PUBLICATION_REPORT_COMMENT_STATUS,
	PUBLICATION_SUBMITTED_STATUS,
	RESIGNED_STATUS
} from '@/hooks/OrderFlowHooks';

const dashboardApi = new DashboardApi();

const { user } = storeToRefs(useUserStore());
const { t } = useI18n();
const { green400, white } = variables;

const loading = ref<boolean>(false);
const domainsPerPage = ref<number>(10);
const totalDomains = ref<number | null>(0);
const ordersPerPage = ref<number>(10);
const totalOrders = ref<number | null>(0);
const scrollBox = ref<any>(null);
const domainItems = ref<Array<IActionItemsItem>>([]);
const orderItems = ref<Array<IActionOrdersItemsItem>>([]);
const selectedToDoItems = ref<string>(ORDER_TO_DO_TYPE);

const actionsDomainList = async() => {
	loading.value = true;
	if (null == user.value) {
		toast.error(`${t('dashboard.toast.errorGetActionsList')}`);
		loading.value = false;
		return;
	}
	try {
		const result = await dashboardApi.actionsItemsList(1, domainsPerPage.value, user.value.id);
		if (!result.isSuccess) {
			toast.error(`${t('dashboard.toast.errorGetActionsList')}`);
			loading.value = false;
			return;
		}
		domainItems.value = result.payload.data;
		totalDomains.value = result.payload.total;
	} catch (e) {
		toast.error(`${t('dashboard.toast.errorGetActionsList')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
if (null != user.value && isPublisher(user.value)) {
	actionsDomainList();
}

const actionOrderList = async() => {
	loading.value = true;
	if (null == user.value) {
		toast.error(`${t('dashboard.toast.errorGetActionsList')}`);
		loading.value = false;
		return;
	}
	try {
		const result = await dashboardApi.actionsOrdersItemsList(1, ordersPerPage.value);
		if (!result.isSuccess) {
			toast.error(`${t('dashboard.toast.errorGetActionsList')}`);
			loading.value = false;
			return;
		}
		orderItems.value = result.payload.data;
		totalOrders.value = result.payload.total;
	} catch (e) {
		toast.error(`${t('dashboard.toast.errorGetActionsList')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
actionOrderList();

const handleScroll = async() => {
	const scrollElement = scrollBox.value;
	if (scrollElement) {
		const isAtBottom = scrollElement.scrollTop + scrollElement.clientHeight >= scrollElement.scrollHeight;
		if (isAtBottom) {
			if (selectedToDoItems.value === WEBSITES_TO_DO_TYPE && null != totalDomains.value && domainsPerPage.value < totalDomains.value) {
				domainsPerPage.value = domainItems.value.length === domainsPerPage.value ? domainsPerPage.value + 10 : domainsPerPage.value;
				await actionsDomainList();
			}
			if (selectedToDoItems.value === ORDER_TO_DO_TYPE && null != totalOrders.value && ordersPerPage.value < totalOrders.value) {
				ordersPerPage.value = orderItems.value.length === ordersPerPage.value ? ordersPerPage.value + 10 : ordersPerPage.value;
				await actionOrderList();
			}
		}
	}
};

const goToEditWebsite = (websiteUuid:string) => {
	router.push(`/websites/${websiteUuid}`);
};
const goToEditOffer = (offerUuid:string) => {
	router.push(`/websites/offer/${offerUuid}`);
};
const goToOrder = (offerUuid:string) => {
	router.push(`/order/${offerUuid}`);
};

const handleChangeToDoList = (toDoListName:string) => {
	selectedToDoItems.value = toDoListName;
};

const handleRedirect = (toDoListName:string) => {
	if (toDoListName === WEBSITES_TO_DO_TYPE) {
		router.push('/websites');
	}
	if (toDoListName === ORDER_TO_DO_TYPE) {
		router.push('/order/publication');
	}
};
</script>

<style scoped lang="scss">
.publisher-to-do-actions {
	&__title {
		font-size: 24px;
		font-weight: 700;
		color: $primary-400;
		margin-bottom: 20px;
		width: max-content;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__wrapper {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 24px;
		background-color: $white-100;
		padding: 24px;
		height: 342px;
		overflow-y: scroll;
		
		&--border {
			overflow-y: auto;
		}
		
		&--border-top {
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			overflow-y: auto;
			height: 426px;
		}
	}
	
	&__action-wrapper {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}
	
	&__item-wrapper {
		display: flex;
		align-items: center;
		gap: 16px;
		border-top: 1px solid $grey-250;
		padding: 16px 0;
	}
	
	&__dot {
		width: 4px;
		height: 40px;
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		background-color: $secondary-400;
		
		&--red {
			background-color: $secondary-400;
		}
		
		&--yellow {
			background-color: $yellow-500;
		}
		
		&--green {
			background-color: $green-400;
		}
	}
	
	&__text-wrapper {
		color: $primary-400;
		font-size: 12px;
		
		span {
			font-weight: 800;
		}
	}
	
	&__deadline-text {
		color: $grey-400;
		font-size: 10px;
		
		span {
			color: $secondary-400;
		}
	}
	
	&__btn-wrapper {
		margin-left: auto;
	}
	
	&__btn-action {
		width: 35px;
		height: 35px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $primary-400;
		border-radius: 50%;
		cursor: pointer;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__plug-wrapper {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
	}
	
	&__plug-text {
		font-size: 18px;
		color: $grey-400;
	}
	
	&__top-btn-wrapper {
		display: flex;
		align-items: flex-start;
		gap: 10px;
		background-color: $white-100;
		padding: 24px;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}
	
	&__top-btn-item {
		position: relative;
		display: flex;
		gap: 10px;
		align-items: center;
		justify-content: center;
		background-color: $primary-400;
		border-radius: 8px;
		height: 36px;
		width: 120px;
		cursor: pointer;
		transition: background-color 0.3s;
		
		&:hover {
			background-color: $green-400;
		}
		
		&--active {
			background-color: $green-400;
		}
	}
	
	&__notification-dot {
		position: absolute;
		width: 15px;
		height: 15px;
		background-color: $secondary-400;
		border-radius: 50%;
		top: -5px;
		right: -5px;
	}
	
	&__top-btn-text {
		font-size: 14px;
		font-weight: 700;
		color: $white-100;
	}
	
	&__question-wrapper {
		position: relative;
		margin-left: auto;
		background-color: $grey-250;
		padding: 7px 8px 3px;
		border-radius: 8px;
		
		&:hover {
			.publisher-to-do-actions__question-tooltip-wrapper {
				display: block;
				position: absolute;
				top: 35px;
				right: 0;
				background-color: $grey-250;
				border-radius: 8px;
				z-index: 1;
			}
		}
	}
	
	&__question-tooltip-wrapper {
		display: none;
	}
	
	&__question-tooltip-title {
		font-size: 12px;
		font-weight: 700;
		text-align: center;
		width: max-content;
		border-bottom: 1px solid $white-100;
		padding: 15px 30px;
	}
	
	&__question-tooltip-item {
		position: relative;
		padding: 15px 30px;
		font-size: 12px;
		font-weight: 400;
		border-bottom: 1px solid $white-100;
		
		&:before {
			content: '';
			position: absolute;
			top: 7px;
			left: 17px;
			width: 4px;
			height: 70%;
			background-color: $secondary-400;
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
		}
		
		&--red {
			&:before {
				background-color: $secondary-400;
			}
		}
		
		&--yellow {
			&:before {
				background-color: $yellow-500;
			}
		}
		
		&--green {
			&:before {
				background-color: $green-400;
			}
		}
	}
	
	&__redirect-btn-wrapper {
		background-color: $white-100;
		padding: 31px 10px;
		display: flex;
		justify-content: center;
		
		&:deep(.m-btn) {
			border-radius: 100px;
			font-size: 12px;
			font-weight: 600;
		}
	}
	
	&__bottom-blank {
		width: 100%;
		height: 20px;
		background-color: $white-100;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}
}
</style>
